@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

:root {
     font-family: "DM Sans", sans-serif;
     font-feature-settings: "cv02", "cv03", "cv04", "cv11";
     font-variation-settings: normal;
     --font-family: "DM Sans", sans-serif;
     --font-feature-settings: "cv02", "cv03", "cv04", "cv11";
     --surface-a: #ffffff;
     --surface-b: #f9fafb;
     --surface-c: #f3f4f6;
     --surface-d: #e5e7eb;
     --surface-e: #ffffff;
     --surface-f: #ffffff;
     --text-color: #33425B;
     --text-color-secondary: #6b7280;
     --primary-color: #005693;
     --primary-color-text: #ffffff;
     --surface-0: #ffffff;
     --surface-50: #f9fafb;
     --surface-100: #f3f4f6;
     --surface-200: #EF8004;
     --surface-300: #d1d5db;
     --surface-400: #9ca3af;
     --surface-500: #6b7280;
     --surface-600: #005693;
     --surface-700: #33425B !important;
     --surface-800: #1f2937;
     --surface-900: #111827;
     --gray: #9B9A9A;
     --gray-50: #f9fafb;
     --gray-100: #f3f4f6;
     --gray-200: #e5e7eb;
     --gray-300: #d1d5db;
     --gray-400: #9ca3af;
     --gray-500: #6b7280;
     --gray-600: #33425B;
     --gray-700: #374151;
     --gray-800: #1f2937;
     --gray-900: #111827;
     --content-padding: 1.25rem;
     --inline-spacing: 0.5rem;
     --border-radius: 4px;
     --surface-ground: #f9fafb;
     --surface-section: #ffffff;
     --surface-card: #ffffff;
     --surface-overlay: #ffffff;
     --surface-border: #dfe7ef;
     --surface-hover: #f6f9fc;
     --focus-ring: 0 0 0 0.2rem #BFDBFE;
     --maskbg: rgba(0, 0, 0, 0.4);
     --highlight-bg: #EFF6FF;
     --highlight-text-color: #005693;
     --primary-color-bg: #013357;
     --always-white: #FAFBFC;
     --card-bg: #FAFBFC;
     --card-txt-color: #33425B;
     --table-text: #7F8F9A;
     --secondary-color: #EF8004;
     color-scheme: light;
     --dataview-title: #005693;
}

@font-face {
     font-family: "Inter var";
     font-weight: 100 900;
     font-display: swap;
     font-style: normal;
     font-named-instance: "Regular";
     src: url("./fonts/Inter-roman.var.woff2?v=3.19") format("woff2");
}

@font-face {
     font-family: "Inter var";
     font-weight: 100 900;
     font-display: swap;
     font-style: italic;
     font-named-instance: "Italic";
     src: url("./fonts/Inter-italic.var.woff2?v=3.19") format("woff2");
}

:root {
     --blue-50: #f5f9ff;
     --blue-100: #d0e1fd;
     --blue-200: #abc9fb;
     --blue-300: #85b2f9;
     --blue-400: #609af8;
     --blue-500: #005693;
     --blue-600: #326fd1;
     --blue-700: #295bac;
     --blue-800: #204887;
     --blue-900: #183462;
     --green-50: #f4fcf7;
     --green-100: #caf1d8;
     --green-200: #a0e6ba;
     --green-300: #76db9b;
     --green-400: #4cd07d;
     --green-500: #22c55e;
     --green-600: #1da750;
     --green-700: #3DB500;
     --green-800: #136c34;
     --green-900: #0e4f26;
     --yellow-50: #fefbf3;
     --yellow-100: #faedc4;
     --yellow-200: #f6de95;
     --yellow-300: #f2d066;
     --yellow-400: #eec137;
     --yellow-500: #eab308;
     --yellow-600: #c79807;
     --yellow-700: #a47d06;
     --yellow-800: #816204;
     --yellow-900: #5e4803;
     --cyan-50: #f3fbfd;
     --cyan-100: #c3edf5;
     --cyan-200: #94e0ed;
     --cyan-300: #65d2e4;
     --cyan-400: #35c4dc;
     --cyan-500: #06b6d4;
     --cyan-600: #059bb4;
     --cyan-700: #047f94;
     --cyan-800: #036475;
     --cyan-900: #024955;
     --pink-50: #fef6fa;
     --pink-100: #fad3e7;
     --pink-200: #f7b0d3;
     --pink-300: #f38ec0;
     --pink-400: #f06bac;
     --pink-500: #ec4899;
     --pink-600: #c93d82;
     --pink-700: #a5326b;
     --pink-800: #822854;
     --pink-900: #5e1d3d;
     --indigo-50: #f7f7fe;
     --indigo-100: #dadafc;
     --indigo-200: #bcbdf9;
     --indigo-300: #9ea0f6;
     --indigo-400: #8183f4;
     --indigo-500: #6366f1;
     --indigo-600: #5457cd;
     --indigo-700: #4547a9;
     --indigo-800: #363885;
     --indigo-900: #282960;
     --teal-50: #f3fbfb;
     --teal-100: #c7eeea;
     --teal-200: #9ae0d9;
     --teal-300: #6dd3c8;
     --teal-400: #41c5b7;
     --teal-500: #14b8a6;
     --teal-600: #119c8d;
     --teal-700: #0e8174;
     --teal-800: #0b655b;
     --teal-900: #084a42;
     --orange-50: #fff8f3;
     --orange-100: #feddc7;
     --orange-200: #fcc39b;
     --orange-300: #fba86f;
     --orange-400: #fa8e42;
     --orange-500: #f97316;
     --orange-600: #d46213;
     --orange-700: #ae510f;
     --orange-800: #893f0c;
     --orange-900: #642e09;
     --bluegray-50: #f7f8f9;
     --bluegray-100: #dadee3;
     --bluegray-200: #bcc3cd;
     --bluegray-300: #9fa9b7;
     --bluegray-400: #818ea1;
     --bluegray-500: #64748b;
     --bluegray-600: #556376;
     --bluegray-700: #465161;
     --bluegray-800: #37404c;
     --bluegray-900: #282e38;
     --purple-50: #fbf7ff;
     --purple-100: #ead6fd;
     --purple-200: #dab6fc;
     --purple-300: #c996fa;
     --purple-400: #b975f9;
     --purple-500: #a855f7;
     --purple-600: #8f48d2;
     --purple-700: #763cad;
     --purple-800: #5c2f88;
     --purple-900: #432263;
     --red-50: #fff5f5;
     --red-100: #ffd0ce;
     --red-200: #ffaca7;
     --red-300: #ff8780;
     --red-400: #ff6259;
     --red-500: #ff3d32;
     --red-600: #d9342b;
     --red-700: #D80914;
     --red-800: #8c221c;
     --red-900: #661814;
     --primary-50: #f5f9ff;
     --primary-100: #d0e1fd;
     --primary-200: #abc9fb;
     --primary-300: #85b2f9;
     --primary-400: #609af8;
     --primary-500: #005693;
     --primary-600: #326fd1;
     --primary-700: #295bac;
     --primary-800: #204887;
     --primary-900: #183462;
}

body {
     font-size: 1rem;
     color: var(--text-color);
     background: var(--surface-ground);
}

.p-editor-container .p-editor-toolbar {
     background: #f9fafb;
     border-top-right-radius: 4px;
     border-top-left-radius: 4px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
     border: 1px solid #e5e7eb;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
     stroke: #6b7280;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
     fill: #6b7280;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
     border: 0 none;
     color: #6b7280;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
     color: var(--text-color);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
     stroke: var(--text-color);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
     fill: var(--text-color);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
     color: var(--text-color);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
     stroke: var(--text-color);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
     fill: var(--text-color);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
     background: #ffffff;
     border: 0 none;
     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     border-radius: 4px;
     padding: 0.75rem 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
     color: var(--text-color);
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
     color: var(--text-color);
     background: #f3f4f6;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
     padding: 0.75rem 1.25rem;
}

.p-editor-container .p-editor-content {
     border-bottom-right-radius: 4px;
     border-bottom-left-radius: 4px;
}

.p-editor-container .p-editor-content.ql-snow {
     border: 1px solid #e5e7eb;
}

.p-editor-container .p-editor-content .ql-editor {
     background: #ffffff;
     color: var(--text-color);
     border-bottom-right-radius: 4px;
     border-bottom-left-radius: 4px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
     color: var(--text-color);
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
     stroke: var(--text-color);
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
     fill: var(--text-color);
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
     color: #005693;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
     stroke: #005693;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
     fill: #005693;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
     color: #005693;
}

@layer primeng {
     * {
          box-sizing: border-box;
     }

     .p-component {
          font-family: var(--font-family);
          font-feature-settings: var(--font-feature-settings, normal);
          font-size: 1rem;
          font-weight: normal;
     }

     .p-component-overlay {
          background-color: rgba(0, 0, 0, 0.4);
          transition-duration: 0.2s;
     }

     .p-disabled,
     .p-component:disabled {
          opacity: 0.6;
     }

     .p-error {
          color: #e24c4c;
     }

     .p-text-secondary {
          color: #6b7280;
     }

     .pi {
          font-size: 1rem;
     }

     .p-icon {
          width: 1rem;
          height: 1rem;
     }

     .p-link {
          font-family: var(--font-family);
          font-feature-settings: var(--font-feature-settings, normal);
          font-size: 1rem;
          border-radius: 4px;
     }

     .p-link:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-component-overlay-enter {
          animation: p-component-overlay-enter-animation 150ms forwards;
     }

     .p-component-overlay-leave {
          animation: p-component-overlay-leave-animation 150ms forwards;
     }

     @keyframes p-component-overlay-enter-animation {
          from {
               background-color: transparent;
          }

          to {
               background-color: var(--maskbg);
          }
     }

     @keyframes p-component-overlay-leave-animation {
          from {
               background-color: var(--maskbg);
          }

          to {
               background-color: transparent;
          }
     }

     .p-autocomplete .p-autocomplete-loader {
          right: 0.75rem;
     }

     .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
          right: 3.75rem;
     }

     .p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
          border-color: #005693;
     }

     .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-autocomplete .p-autocomplete-multiple-container {
          padding: 0.375rem 0.75rem;
          gap: 0.5rem;
     }

     .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
          padding: 0.375rem 0;
     }

     .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
          font-family: var(--font-family);
          font-feature-settings: var(--font-feature-settings, normal);
          font-size: 1rem;
          color: var(--text-color);
          padding: 0;
          margin: 0;
     }

     .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
          padding: 0.375rem 0.75rem;
          background: #e5e7eb;
          color: var(--text-color);
          border-radius: 16px;
     }

     .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
          margin-left: 0.5rem;
     }

     .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
          background: #d1d5db;
          color: var(--text-color);
     }

     .p-autocomplete.p-invalid.p-component>.p-inputtext {
          border-color: #e24c4c;
     }

     .p-autocomplete-panel {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-autocomplete-panel .p-autocomplete-items {
          padding: 0.75rem 0;
     }

     .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
          margin: 0;
          padding: 0.75rem 1.25rem;
          border: 0 none;
          color: var(--text-color);
          background: transparent;
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:first-child {
          margin-top: 0;
     }

     .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
          color: var(--text-color);;
          background: #EFF6FF;
     }

     .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
          margin: 0;
          padding: 0.75rem 1.25rem;
          color: #374151;
          background: #ffffff;
          font-weight: 700;
     }

     .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
          padding: 0.75rem 1.25rem;
          color: var(--text-color);
          background: transparent;
     }

     p-autocomplete.ng-dirty.ng-invalid>.p-autocomplete>.p-inputtext {
          border-color: #e24c4c;
     }

     p-autocomplete.p-autocomplete-clearable .p-inputtext {
          padding-right: 2.5rem;
     }

     p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
          color: #6b7280;
          right: 0.75rem;
     }

     p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
          color: #6b7280;
          right: 3.75rem;
     }

     p-calendar.ng-dirty.ng-invalid>.p-calendar>.p-inputtext {
          border-color: #e24c4c;
     }

     .p-calendar:not(.p-calendar-disabled).p-focus>.p-inputtext {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-datepicker {
          padding: 0.5rem;
          background: #ffffff;
          color: var(--text-color);
          border: 1px solid #d1d5db;
          border-radius: 4px;
     }

     .p-datepicker:not(.p-datepicker-inline) {
          background: #ffffff;
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
          background: #ffffff;
     }

     .p-datepicker .p-datepicker-header {
          padding: 0.5rem;
          color: var(--text-color);
          background: #ffffff;
          font-weight: 600;
          margin: 0;
          border-bottom: 1px solid #e5e7eb;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-datepicker .p-datepicker-header .p-datepicker-prev,
     .p-datepicker .p-datepicker-header .p-datepicker-next {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
     .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
     .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-datepicker .p-datepicker-header .p-datepicker-title {
          line-height: 2rem;
     }

     .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
     .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
          color: var(--text-color);
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          font-weight: 600;
          padding: 0.5rem;
     }

     .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
     .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
          color: #005693;
     }

     .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
          margin-right: 0.5rem;
     }

     .p-datepicker table {
          font-size: 1rem;
          margin: 0.5rem 0;
     }

     .p-datepicker table th {
          padding: 0.5rem;
     }

     .p-datepicker table th>span {
          width: 2.5rem;
          height: 2.5rem;
     }

     .p-datepicker table td {
          padding: 0.5rem;
     }

     .p-datepicker table td>span {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          transition: box-shadow 0.2s;
          border: 1px solid transparent;
     }

     .p-datepicker table td>span.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-datepicker table td>span:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-datepicker table td.p-datepicker-today>span {
          background: #d1d5db;
          color: var(--text-color);
          border-color: transparent;
     }

     .p-datepicker table td.p-datepicker-today>span.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-datepicker .p-datepicker-buttonbar {
          padding: 1rem 0;
          border-top: 1px solid #e5e7eb;
     }

     .p-datepicker .p-datepicker-buttonbar .p-button {
          width: auto;
     }

     .p-datepicker .p-timepicker {
          border-top: 1px solid #e5e7eb;
          padding: 0.5rem;
     }

     .p-datepicker .p-timepicker button {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-datepicker .p-timepicker button:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-datepicker .p-timepicker button:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-datepicker .p-timepicker button:last-child {
          margin-top: 0.2em;
     }

     .p-datepicker .p-timepicker span {
          font-size: 1.25rem;
     }

     .p-datepicker .p-timepicker>div {
          padding: 0 0.5rem;
     }

     .p-datepicker.p-datepicker-timeonly .p-timepicker {
          border-top: 0 none;
     }

     .p-datepicker .p-monthpicker {
          margin: 0.5rem 0;
     }

     .p-datepicker .p-monthpicker .p-monthpicker-month {
          padding: 0.5rem;
          transition: box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-datepicker .p-yearpicker {
          margin: 0.5rem 0;
     }

     .p-datepicker .p-yearpicker .p-yearpicker-year {
          padding: 0.5rem;
          transition: box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
          border-left: 1px solid #e5e7eb;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          padding-top: 0;
          padding-bottom: 0;
     }

     .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
          padding-left: 0;
          border-left: 0 none;
     }

     .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
          padding-right: 0;
     }

     .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
          background: #f3f4f6;
     }

     .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
          background: #f3f4f6;
     }

     .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
          background: #f3f4f6;
     }

     .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     p-calendar.p-calendar-clearable .p-inputtext {
          padding-right: 2.5rem;
     }

     p-calendar.p-calendar-clearable .p-calendar-clear-icon {
          color: #6b7280;
          right: 0.75rem;
     }

     p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
          color: #6b7280;
          right: 3.75rem;
     }

     @media screen and (max-width: 769px) {

          .p-datepicker table th,
          .p-datepicker table td {
               padding: 0;
          }
     }

     .p-cascadeselect {
          background: #ffffff;
          border: 1px solid #d1d5db;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-cascadeselect:not(.p-disabled):hover {
          border-color: #005693;
     }

     .p-cascadeselect:not(.p-disabled).p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-cascadeselect .p-cascadeselect-label {
          background: transparent;
          border: 0 none;
          padding: 0.75rem 0.75rem;
     }

     .p-cascadeselect .p-cascadeselect-label.p-placeholder {
          color: #6b7280;
     }

     .p-cascadeselect .p-cascadeselect-label:enabled:focus {
          outline: 0 none;
          box-shadow: none;
     }

     .p-cascadeselect .p-cascadeselect-trigger {
          background: transparent;
          color: #6b7280;
          width: 3rem;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-cascadeselect.p-invalid.p-component {
          border-color: #e24c4c;
     }

     .p-cascadeselect.p-variant-filled {
          background-color: #f3f4f6;
     }

     .p-cascadeselect.p-variant-filled:enabled:hover {
          background-color: #f3f4f6;
     }

     .p-cascadeselect.p-variant-filled:enabled:focus {
          background-color: #ffffff;
     }

     .p-cascadeselect-panel {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-cascadeselect-panel .p-cascadeselect-items {
          padding: 0.75rem 0;
     }

     .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
          margin: 0;
          border: 0 none;
          color: var(--text-color);
          background: transparent;
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:first-child {
          margin-top: 0;
     }

     .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
          padding: 0.75rem 1.25rem;
     }

     .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
          font-size: 0.875rem;
     }

     .p-input-filled .p-cascadeselect {
          background: #f3f4f6;
     }

     .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
          background-color: #ffffff;
     }

     p-cascadeselect.ng-dirty.ng-invalid>.p-cascadeselect {
          border-color: #e24c4c;
     }

     p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
          padding-right: 0.75rem;
     }

     p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
          color: #6b7280;
          right: 3rem;
     }

     .p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
          box-shadow: none;
          border-radius: 0;
          padding: 0.25rem 0 0.25rem 0.5rem;
     }

     .p-overlay-modal .p-cascadeselect-item-active>.p-cascadeselect-item-content .p-cascadeselect-group-icon {
          transform: rotate(90deg);
     }

     .p-checkbox {
          width: 22px;
          height: 22px;
     }

     .p-checkbox .p-checkbox-box {
          border: 2px solid #d1d5db;
          background: #ffffff;
          width: 22px;
          height: 22px;
          color: var(--text-color);
          border-radius: 4px;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          outline-color: transparent;
     }

     .p-checkbox .p-checkbox-box .p-checkbox-icon {
          transition-duration: 0.2s;
          color: #ffffff;
          font-size: 14px;
     }

     .p-checkbox .p-checkbox-box .p-icon {
          width: 14px;
          height: 14px;
     }

     .p-checkbox .p-checkbox-box.p-highlight {
          border-color: #005693;
          background: #005693;
     }

     .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
          border-color: #005693;
     }

     .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
          border-color: #005693;
          background: #005693;
          color: #ffffff;
     }

     .p-checkbox.p-variant-filled .p-checkbox-box {
          background-color: #f3f4f6;
     }

     .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
          background: #005693;
     }

     .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box:hover {
          background-color: #f3f4f6;
     }

     .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
          background: #005693;
     }

     p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
          border-color: #e24c4c;
     }

     .p-input-filled .p-checkbox .p-checkbox-box {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
          background: #005693;
     }

     .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
          background: #005693;
     }

     .p-checkbox-label {
          margin-left: 0.5rem;
     }

     p-tristatecheckbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
          border-color: #e24c4c;
     }

     .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
          border-color: #005693;
     }

     .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-chips .p-chips-multiple-container {
          padding: 0.375rem 0.75rem;
          gap: 0.5rem;
     }

     .p-chips .p-chips-multiple-container .p-chips-token {
          padding: 0.375rem 0.75rem;
          margin-right: 0.5rem;
          background: #e5e7eb;
          color: var(--text-color);
          border-radius: 16px;
     }

     .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
          background: #d1d5db;
          color: var(--text-color);
     }

     .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
          margin-left: 0.5rem;
     }

     .p-chips .p-chips-multiple-container .p-chips-input-token {
          padding: 0.375rem 0;
     }

     .p-chips .p-chips-multiple-container .p-chips-input-token input {
          font-family: var(--font-family);
          font-feature-settings: var(--font-feature-settings, normal);
          font-size: 1rem;
          color: var(--text-color);
          padding: 0;
          margin: 0;
     }

     p-chips.ng-dirty.ng-invalid>.p-chips>.p-inputtext {
          border-color: #e24c4c;
     }

     p-chips.p-chips-clearable .p-inputtext {
          padding-right: 1.75rem;
     }

     p-chips.p-chips-clearable .p-chips-clear-icon {
          color: #6b7280;
          right: 0.75rem;
     }

     .p-colorpicker-preview,
     .p-fluid .p-colorpicker-preview.p-inputtext {
          width: 2rem;
          height: 2rem;
     }

     .p-colorpicker-panel {
          background: #323232;
          border: 1px solid #191919;
     }

     .p-colorpicker-panel .p-colorpicker-color-handle,
     .p-colorpicker-panel .p-colorpicker-hue-handle {
          border-color: #ffffff;
     }

     .p-colorpicker-overlay-panel {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-dropdown {
          background: #ffffff;
          border: 1px solid #d1d5db;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-dropdown:not(.p-disabled):hover {
          border-color: #005693;
     }

     .p-dropdown:not(.p-disabled).p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-dropdown.p-dropdown-clearable .p-dropdown-label {
          padding-right: 1.75rem;
     }

     .p-dropdown .p-dropdown-label {
          background: transparent;
          border: 0 none;
     }

     .p-dropdown .p-dropdown-label.p-placeholder {
          color: #6b7280;
     }

     .p-dropdown .p-dropdown-label:focus,
     .p-dropdown .p-dropdown-label:enabled:focus {
          outline: 0 none;
          box-shadow: none;
     }

     .p-dropdown .p-dropdown-trigger {
          background: transparent;
          color: #6b7280;
          width: 3rem;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-dropdown .p-dropdown-clear-icon {
          color: #6b7280;
          right: 3rem;
     }

     .p-dropdown.p-invalid.p-component {
          border-color: #e24c4c;
     }

     .p-dropdown.p-variant-filled {
          background-color: #f3f4f6;
     }

     .p-dropdown.p-variant-filled:enabled:hover {
          background-color: #f3f4f6;
     }

     .p-dropdown.p-variant-filled:enabled:focus {
          background-color: #ffffff;
     }

     .p-dropdown-panel {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-dropdown-panel .p-dropdown-header {
          padding: 0.75rem 1.25rem;
          border-bottom: 1px solid #e5e7eb;
          color: #374151;
          background: #f9fafb;
          margin: 0;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
          padding-right: 1.75rem;
          margin-right: -1.75rem;
     }

     .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
          right: 0.75rem;
          color: #6b7280;
     }

     .p-dropdown-panel .p-dropdown-items {
          padding: 0.75rem 0;
     }

     .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
          margin: 0;
          padding: 0.75rem 1.25rem;
          border: 0 none;
          color: var(--text-color);
          background: transparent;
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
          margin-top: 0;
     }

     .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
          margin: 0;
          padding: 0.75rem 1.25rem;
          color: #374151;
          background: #ffffff;
          font-weight: 700;
     }

     .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
          padding: 0.75rem 1.25rem;
          color: var(--text-color);
          background: transparent;
     }

     .p-input-filled .p-dropdown {
          background: #f3f4f6;
     }

     .p-input-filled .p-dropdown:not(.p-disabled):hover {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
          background-color: #ffffff;
     }

     .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
          background-color: transparent;
     }

     p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
          border-color: #e24c4c;
     }

     .p-icon-field .p-input-icon {
          position: absolute;
          top: 50%;
          margin-top: -0.5rem;
     }

     .p-inputgroup-addon {
          background: #f3f4f6;
          color: #6b7280;
          border-top: 1px solid #d1d5db;
          border-left: 1px solid #d1d5db;
          border-bottom: 1px solid #d1d5db;
          padding: 0.75rem 0.75rem;
          min-width: 3rem;
     }

     .p-inputgroup-addon:last-child {
          border-right: 1px solid #d1d5db;
     }

     .p-inputgroup>.p-component,
     .p-inputgroup>.p-inputwrapper>.p-inputtext,
     .p-inputgroup>.p-float-label>.p-component {
          border-radius: 0;
          margin: 0;
     }

     .p-inputgroup>.p-component+.p-inputgroup-addon,
     .p-inputgroup>.p-inputwrapper>.p-inputtext+.p-inputgroup-addon,
     .p-inputgroup>.p-float-label>.p-component+.p-inputgroup-addon {
          border-left: 0 none;
     }

     .p-inputgroup>.p-component:focus,
     .p-inputgroup>.p-inputwrapper>.p-inputtext:focus,
     .p-inputgroup>.p-float-label>.p-component:focus {
          z-index: 1;
     }

     .p-inputgroup>.p-component:focus~label,
     .p-inputgroup>.p-inputwrapper>.p-inputtext:focus~label,
     .p-inputgroup>.p-float-label>.p-component:focus~label {
          z-index: 1;
     }

     .p-inputgroup-addon:first-child,
     .p-inputgroup button:first-child,
     .p-inputgroup input:first-child,
     .p-inputgroup>.p-inputwrapper:first-child>.p-component,
     .p-inputgroup>.p-inputwrapper:first-child>.p-component>.p-inputtext {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-inputgroup .p-float-label:first-child input {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-inputgroup-addon:last-child,
     .p-inputgroup button:last-child,
     .p-inputgroup input:last-child,
     .p-inputgroup>.p-inputwrapper:last-child>.p-component,
     .p-inputgroup>.p-inputwrapper:last-child>.p-component>.p-inputtext {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-inputgroup .p-float-label:last-child input {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-fluid .p-inputgroup .p-button {
          width: auto;
     }

     .p-fluid .p-inputgroup .p-button.p-button-icon-only {
          width: 3rem;
     }

     .p-icon-field-left .p-input-icon:first-of-type {
          left: 0.75rem;
          color: #6b7280;
     }

     .p-icon-field-right .p-input-icon:last-of-type {
          right: 0.75rem;
          color: #6b7280;
     }

     p-inputmask.ng-dirty.ng-invalid>.p-inputtext {
          border-color: #e24c4c;
     }

     p-inputmask.p-inputmask-clearable .p-inputtext {
          padding-right: 2.5rem;
     }

     p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
          color: #6b7280;
          right: 0.75rem;
     }

     .p-inputmask.p-variant-filled {
          background-color: #f3f4f6;
     }

     .p-inputmask.p-variant-filled:enabled:hover {
          background-color: #f3f4f6;
     }

     .p-inputmask.p-variant-filled:enabled:focus {
          background-color: #ffffff;
     }

     p-inputnumber.ng-dirty.ng-invalid>.p-inputnumber>.p-inputtext {
          border-color: #e24c4c;
     }

     p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
          padding-right: 2.5rem;
     }

     p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
          color: #6b7280;
          right: 0.75rem;
     }

     p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-stacked .p-inputnumber-clear-icon {
          right: 3.75rem;
     }

     p-inputnumber.p-inputnumber-clearable .p-inputnumber-buttons-horizontal .p-inputnumber-clear-icon {
          right: 3.75rem;
     }

     p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input {
          background-color: #f3f4f6;
     }

     p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input:enabled:hover {
          background-color: #f3f4f6;
     }

     p-inputnumber.p-inputnumber.p-variant-filled>.p-inputnumber-input:enabled:focus {
          background-color: #ffffff;
     }

     .p-inputotp {
          display: flex;
          align-items: center;
          gap: 0.5rem;
     }

     .p-inputotp-input {
          text-align: center;
          width: 2.5rem;
     }

     .p-inputswitch {
          width: 3rem;
          height: 1.5rem;
     }

     .p-inputswitch .p-inputswitch-slider {
          background: #d1d5db;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          border-radius: 30px;
     }

     .p-inputswitch .p-inputswitch-slider:before {
          background: #ffffff;
          width: 1.25rem;
          height: 1.25rem;
          left: 0.25rem;
          margin-top: -0.625rem;
          border-radius: 50%;
          transition-duration: 0.2s;
     }

     .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
          transform: translateX(1.25rem);
     }

     .p-inputswitch.p-focus .p-inputswitch-slider {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
          background: #b7bcc5;
     }

     .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
          background: #005693;
     }

     .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
          background: #ffffff;
     }

     .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
          background: #013357;
     }

     p-inputswitch.ng-dirty.ng-invalid>.p-inputswitch>.p-inputswitch-slider {
          border-color: #e24c4c;
     }

     .p-inputtext {
          font-family: var(--font-family);
          font-feature-settings: var(--font-feature-settings, normal);
          font-size: 1rem;
          color: var(--text-color);
          background: #ffffff;
          padding: 0.5rem 0.5rem;
          border: 1px solid #d1d5db;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          appearance: none;
          border-radius: 4px;
     }

     .p-inputtext:enabled:hover {
          border-color: #005693;
     }

     .p-inputtext:enabled:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-inputtext.ng-dirty.ng-invalid {
          border-color: #e24c4c;
     }

     .p-inputtext.p-variant-filled {
          background-color: #f3f4f6;
     }

     .p-inputtext.p-variant-filled:enabled:hover {
          background-color: #f3f4f6;
     }

     .p-inputtext.p-variant-filled:enabled:focus {
          background-color: #ffffff;
     }

     .p-inputtext.p-inputtext-sm {
          font-size: 0.875rem;
          padding: 0.65625rem 0.65625rem;
     }

     .p-inputtext.p-inputtext-lg {
          font-size: 1.25rem;
          padding: 0.9375rem 0.9375rem;
     }

     .p-float-label>label {
          left: 0.75rem;
          color: #6b7280;
          transition-duration: 0.2s;
     }

     .p-float-label>.ng-invalid.ng-dirty+label {
          color: #e24c4c;
     }

     .p-input-icon-left>.p-icon-wrapper.p-icon,
     .p-input-icon-left>i:first-of-type {
          left: 0.75rem;
          color: #6b7280;
     }

     .p-input-icon-left>.p-inputtext {
          padding-left: 2.5rem;
     }

     .p-input-icon-left.p-float-label>label {
          left: 2.5rem;
     }

     .p-input-icon-right>.p-icon-wrapper,
     .p-input-icon-right>i:last-of-type {
          right: 0.75rem;
          color: #6b7280;
     }

     .p-input-icon-right>.p-inputtext {
          padding-right: 2.5rem;
     }

     .p-icon-field-left>.p-inputtext {
          padding-left: 2.5rem;
     }

     .p-icon-field-left.p-float-label>label {
          left: 2.5rem;
     }

     .p-icon-field-right>.p-inputtext {
          padding-right: 2.5rem;
     }

     ::-webkit-input-placeholder {
          color: #6b7280;
     }

     :-moz-placeholder {
          color: #6b7280;
     }

     ::-moz-placeholder {
          color: #6b7280;
     }

     :-ms-input-placeholder {
          color: #6b7280;
     }

     .p-input-filled .p-inputtext {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-inputtext:enabled:hover {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-inputtext:enabled:focus {
          background-color: #ffffff;
     }

     .p-inputtext-sm .p-inputtext {
          font-size: 0.875rem;
          padding: 0.65625rem 0.65625rem;
     }

     .p-inputtext-lg .p-inputtext {
          font-size: 1.25rem;
          padding: 0.9375rem 0.9375rem;
     }

     .p-listbox {
          background: #ffffff;
          color: var(--text-color);
          border: 1px solid #d1d5db;
          border-radius: 4px;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-listbox .p-listbox-header {
          padding: 0.75rem 1.25rem;
          border-bottom: 1px solid #e5e7eb;
          color: #374151;
          background: #f9fafb;
          margin: 0;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-listbox .p-listbox-header .p-listbox-filter {
          padding-right: 1.75rem;
     }

     .p-listbox .p-listbox-header .p-listbox-filter-icon {
          right: 0.75rem;
          color: #6b7280;
     }

     .p-listbox .p-listbox-header .p-checkbox {
          margin-right: 0.5rem;
     }

     .p-listbox .p-listbox-list {
          padding: 0.75rem 0;
          outline: 0 none;
     }

     .p-listbox .p-listbox-list .p-listbox-item {
          margin: 0;
          padding: 0.75rem 1.25rem;
          border: 0 none;
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-listbox .p-listbox-list .p-listbox-item:first-child {
          margin-top: 0;
     }

     .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
          margin-right: 0.5rem;
     }

     .p-listbox .p-listbox-list .p-listbox-item-group {
          margin: 0;
          padding: 0.75rem 1.25rem;
          color: #374151;
          background: #ffffff;
          font-weight: 700;
     }

     .p-listbox .p-listbox-list .p-listbox-empty-message {
          padding: 0.75rem 1.25rem;
          color: var(--text-color);
          background: transparent;
     }

     .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover.p-focus {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-listbox.p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     p-listbox.ng-dirty.ng-invalid>.p-listbox {
          border-color: #e24c4c;
     }

     .p-multiselect {
          background: #ffffff;
          border: 1px solid #d1d5db;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-multiselect:not(.p-disabled):hover {
          border-color: #005693;
     }

     .p-multiselect:not(.p-disabled).p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-multiselect .p-multiselect-label {
          padding: 0.75rem 0.75rem;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-multiselect .p-multiselect-label.p-placeholder {
          color: #6b7280;
     }

     .p-multiselect.p-multiselect-chip .p-multiselect-token {
          padding: 0.375rem 0.75rem;
          margin-right: 0.5rem;
          background: #e5e7eb;
          color: var(--text-color);
          border-radius: 16px;
     }

     .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
          margin-left: 0.5rem;
     }

     .p-multiselect .p-multiselect-trigger {
          background: transparent;
          color: #6b7280;
          width: 3rem;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-multiselect.p-variant-filled {
          background: #f3f4f6;
     }

     .p-multiselect.p-variant-filled:not(.p-disabled):hover {
          background-color: #f3f4f6;
     }

     .p-multiselect.p-variant-filled:not(.p-disabled).p-focus {
          background-color: #ffffff;
     }

     .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
          padding: 0.375rem 0.75rem;
     }

     .p-multiselect-clearable .p-multiselect-label-container {
          padding-right: 1.75rem;
     }

     .p-multiselect-clearable .p-multiselect-clear-icon {
          color: #6b7280;
          right: 3rem;
     }

     .p-multiselect-panel {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-multiselect-panel .p-multiselect-header {
          padding: 0.75rem 1.25rem;
          border-bottom: 1px solid #e5e7eb;
          color: #374151;
          background: #f9fafb;
          margin: 0;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
          padding-right: 1.75rem;
     }

     .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
          right: 0.75rem;
          color: #6b7280;
     }

     .p-multiselect-panel .p-multiselect-header .p-checkbox {
          margin-right: 0.5rem;
     }

     .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
          margin-left: 0.5rem;
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-multiselect-panel .p-multiselect-items {
          padding: 0.75rem 0;
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
          margin: 0;
          padding: 0.75rem 1.25rem;
          border: 0 none;
          color: var(--text-color);
          background: transparent;
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-item:first-child {
          margin-top: 0;
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
          margin-right: 0.5rem;
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
          margin: 0;
          padding: 0.75rem 1.25rem;
          color: #374151;
          background: #ffffff;
          font-weight: 700;
     }

     .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
          padding: 0.75rem 1.25rem;
          color: var(--text-color);
          background: transparent;
     }

     .p-input-filled .p-multiselect {
          background: #f3f4f6;
     }

     .p-input-filled .p-multiselect:not(.p-disabled):hover {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
          background-color: #ffffff;
     }

     p-multiselect.ng-dirty.ng-invalid>.p-multiselect {
          border-color: #e24c4c;
     }

     p-password.ng-invalid.ng-dirty>.p-password>.p-inputtext {
          border-color: #e24c4c;
     }

     .p-password-panel {
          padding: 1.25rem;
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
     }

     .p-password-panel .p-password-meter {
          margin-bottom: 0.5rem;
          background: #e5e7eb;
     }

     .p-password-panel .p-password-meter .p-password-strength.weak {
          background: #ea5455;
     }

     .p-password-panel .p-password-meter .p-password-strength.medium {
          background: #ff9f42;
     }

     .p-password-panel .p-password-meter .p-password-strength.strong {
          background: #29c76f;
     }

     p-password.p-password-clearable .p-password-input {
          padding-right: 2.5rem;
     }

     p-password.p-password-clearable .p-password-clear-icon {
          color: #6b7280;
          right: 0.75rem;
     }

     p-password.p-password-clearable.p-password-mask .p-password-input {
          padding-right: 4.25rem;
     }

     p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
          color: #6b7280;
          right: 2.5rem;
     }

     .p-radiobutton {
          width: 22px;
          height: 22px;
     }

     .p-radiobutton .p-radiobutton-box {
          border: 2px solid #d1d5db;
          background: #ffffff;
          width: 22px;
          height: 22px;
          color: var(--text-color);
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          outline-color: transparent;
     }

     .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
          border-color: #005693;
     }

     .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
          width: 12px;
          height: 12px;
          transition-duration: 0.2s;
          background-color: #ffffff;
     }

     .p-radiobutton .p-radiobutton-box.p-highlight {
          border-color: #005693;
          background: #005693;
     }

     .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
          border-color: #005693;
          background: #005693;
          color: #ffffff;
     }

     .p-radiobutton.p-variant-filled .p-radiobutton-box {
          background-color: #f3f4f6;
     }

     .p-radiobutton.p-variant-filled .p-radiobutton-box:not(.p-disabled):hover {
          background-color: #f3f4f6;
     }

     .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight {
          background: #005693;
     }

     .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
          background: #005693;
     }

     p-radiobutton.ng-dirty.ng-invalid>.p-radiobutton>.p-radiobutton-box {
          border-color: #e24c4c;
     }

     .p-input-filled .p-radiobutton .p-radiobutton-box {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
          background: #005693;
     }

     .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
          background: #005693;
     }

     .p-radiobutton-label {
          margin-left: 0.5rem;
     }

     .p-rating {
          gap: 0.5rem;
     }

     .p-rating .p-rating-item {
          border-radius: 50%;
          outline-color: transparent;
          display: inline-flex;
          justify-content: center;
          align-items: center;
     }

     .p-rating .p-rating-item .p-rating-icon {
          color: var(--text-color);
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          font-size: 1.143rem;
     }

     .p-rating .p-rating-item .p-rating-icon.p-icon {
          width: 1.143rem;
          height: 1.143rem;
     }

     .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
          color: #ea5455;
     }

     .p-rating .p-rating-item.p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
          color: #005693;
     }

     .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
          color: #005693;
     }

     .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
          color: #e73d3e;
     }

     .p-selectbutton .p-button {
          background: #ffffff;
          border: 1px solid #d1d5db;
          color: var(--text-color);
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-selectbutton .p-button .p-button-icon-left,
     .p-selectbutton .p-button .p-button-icon-right {
          color: #6b7280;
     }

     .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
          background: #f3f4f6;
          border-color: #d1d5db;
          color: var(--text-color);
     }

     .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
     .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
          color: #374151;
     }

     .p-selectbutton .p-button.p-highlight {
          background: #005693;
          border-color: #005693;
          color: #ffffff;
     }

     .p-selectbutton .p-button.p-highlight .p-button-icon-left,
     .p-selectbutton .p-button.p-highlight .p-button-icon-right {
          color: #ffffff;
     }

     .p-selectbutton .p-button.p-highlight:hover {
          background: #013357;
          border-color: #013357;
          color: #ffffff;
     }

     .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
     .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
          color: #ffffff;
     }

     p-selectbutton.ng-dirty.ng-invalid>.p-selectbutton>.p-button {
          border-color: #e24c4c;
     }

     .p-slider {
          background: #e5e7eb;
          border: 0 none;
          border-radius: 4px;
     }

     .p-slider.p-slider-horizontal {
          height: 0.286rem;
     }

     .p-slider.p-slider-horizontal .p-slider-handle {
          margin-top: -0.5715rem;
          margin-left: -0.5715rem;
     }

     .p-slider.p-slider-vertical {
          height: 100%;
          width: 0.286rem;
     }

     .p-slider.p-slider-vertical .p-slider-handle {
          height: 1.143rem;
          width: 1.143rem;
          margin-left: -0.5715rem;
          margin-bottom: -0.5715rem;
     }

     .p-slider .p-slider-handle {
          height: 1.143rem;
          width: 1.143rem;
          background: #ffffff;
          border: 2px solid #005693;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-slider .p-slider-handle:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-slider .p-slider-range {
          background: #005693;
     }

     .p-slider:not(.p-disabled) .p-slider-handle:hover {
          background: #005693;
          border-color: #005693;
     }

     .p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
     }

     .p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
          transition: width 0.2s;
     }

     .p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
     }

     .p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
          transition: height 0.2s;
     }

     .p-togglebutton.p-button {
          background: #ffffff;
          border: 1px solid #d1d5db;
          color: var(--text-color);
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-togglebutton.p-button .p-button-icon-left,
     .p-togglebutton.p-button .p-button-icon-right {
          color: #6b7280;
     }

     .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
          background: #f3f4f6;
          border-color: #d1d5db;
          color: var(--text-color);
     }

     .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
     .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
          color: #374151;
     }

     .p-togglebutton.p-button.p-highlight {
          background: #005693;
          border-color: #005693;
          color: #ffffff;
     }

     .p-togglebutton.p-button.p-highlight .p-button-icon-left,
     .p-togglebutton.p-button.p-highlight .p-button-icon-right {
          color: #ffffff;
     }

     .p-togglebutton.p-button.p-highlight:hover {
          background: #013357;
          border-color: #013357;
          color: #ffffff;
     }

     .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
     .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
          color: #ffffff;
     }

     p-togglebutton.ng-dirty.ng-invalid>.p-togglebutton.p-button {
          border-color: #e24c4c;
     }

     .p-treeselect {
          background: #ffffff;
          border: 1px solid #d1d5db;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-treeselect:not(.p-disabled):hover {
          border-color: #005693;
     }

     .p-treeselect:not(.p-disabled).p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-treeselect .p-treeselect-label {
          padding: 0.75rem 0.75rem;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-treeselect .p-treeselect-label.p-placeholder {
          color: #6b7280;
     }

     .p-treeselect.p-treeselect-chip .p-treeselect-token {
          padding: 0.375rem 0.75rem;
          margin-right: 0.5rem;
          background: #e5e7eb;
          color: var(--text-color);
          border-radius: 16px;
     }

     .p-treeselect .p-treeselect-trigger {
          background: transparent;
          color: #6b7280;
          width: 3rem;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-treeselect.p-variant-filled {
          background-color: #f3f4f6;
     }

     .p-treeselect.p-variant-filled:enabled:hover {
          background-color: #f3f4f6;
     }

     .p-treeselect.p-variant-filled:enabled:focus {
          background-color: #ffffff;
     }

     p-treeselect.ng-invalid.ng-dirty>.p-treeselect {
          border-color: #e24c4c;
     }

     .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
          padding: 0.375rem 0.75rem;
     }

     .p-treeselect-panel {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-treeselect-panel .p-treeselect-header {
          padding: 0.75rem 1.25rem;
          border-bottom: 1px solid #e5e7eb;
          color: #374151;
          background: #f9fafb;
          margin: 0;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
          margin-right: 0.5rem;
     }

     .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
          padding-right: 1.75rem;
     }

     .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
          right: 0.75rem;
          color: #6b7280;
     }

     .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
          padding-right: 3.5rem;
     }

     .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
          right: 2.5rem;
     }

     .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
          border: 0 none;
     }

     .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
          padding: 0.75rem 1.25rem;
          color: var(--text-color);
          background: transparent;
     }

     .p-input-filled .p-treeselect {
          background: #f3f4f6;
     }

     .p-input-filled .p-treeselect:not(.p-disabled):hover {
          background-color: #f3f4f6;
     }

     .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
          background-color: #ffffff;
     }

     p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
          padding-right: 1.75rem;
     }

     p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
          color: #6b7280;
          right: 3rem;
     }

     .p-button {
          color: #ffffff;
          background: #005693;
          border: 1px solid #005693;
          padding: 0.5rem 1.25rem;
          font-size: 1rem;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          border-radius: 4px;
          outline-color: transparent;
     }

     .p-button:not(:disabled):hover {
          background: #013357;
          color: #ffffff;
          border-color: #013357;
     }

     .p-button:not(:disabled):active {
          background: #005693;
          color: #ffffff;
          border-color: #005693;
     }

     .p-button.p-button-outlined {
          background-color: transparent;
          color: #005693;
          border: 1px solid;
     }

     .p-button.p-button-outlined:not(:disabled):hover {
          background: rgba(59, 130, 246, 0.04);
          color: #005693;
          border: 1px solid;
     }

     .p-button.p-button-outlined:not(:disabled):active {
          background: rgba(59, 130, 246, 0.16);
          color: #005693;
          border: 1px solid;
     }

     .p-button.p-button-outlined.p-button-plain {
          color: #6b7280;
          border-color: #6b7280;
     }

     .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
          background: #f3f4f6;
          color: #6b7280;
     }

     .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
          background: #e5e7eb;
          color: #6b7280;
     }

     .p-button.p-button-text {
          background-color: transparent;
          color: #005693;
          border-color: transparent;
     }

     .p-button.p-button-text:not(:disabled):hover {
          background: rgba(59, 130, 246, 0.04);
          color: #005693;
          border-color: transparent;
     }

     .p-button.p-button-text:not(:disabled):active {
          background: rgba(59, 130, 246, 0.16);
          color: #005693;
          border-color: transparent;
     }

     .p-button.p-button-text.p-button-plain {
          color: #6b7280;
     }

     .p-button.p-button-text.p-button-plain:not(:disabled):hover {
          background: #f3f4f6;
          color: #6b7280;
     }

     .p-button.p-button-text.p-button-plain:not(:disabled):active {
          background: #e5e7eb;
          color: #6b7280;
     }

     .p-button:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-button .p-button-label {
          transition-duration: 0.2s;
     }

     .p-button .p-button-icon-left {
          margin-right: 0.5rem;
     }

     .p-button .p-button-icon-right {
          margin-left: 0.5rem;
     }

     .p-button .p-button-icon-bottom {
          margin-top: 0.5rem;
     }

     .p-button .p-button-icon-top {
          margin-bottom: 0.5rem;
     }

     .p-button .p-badge {
          margin-left: 0.5rem;
          min-width: 1rem;
          height: 1rem;
          line-height: 1rem;
          color: #005693;
          background-color: #ffffff;
     }

     .p-button.p-button-raised {
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
     }

     .p-button.p-button-rounded {
          border-radius: 2rem;
     }

     .p-button.p-button-icon-only {
          width: 3rem;
          padding: 0.75rem 0;
     }

     .p-button.p-button-icon-only .p-button-icon-left,
     .p-button.p-button-icon-only .p-button-icon-right {
          margin: 0;
     }

     .p-button.p-button-icon-only.p-button-rounded {
          border-radius: 50%;
          height: 1rem;
          width: 1.5rem;
     }

     .p-button.p-button-sm {
          font-size: 0.875rem;
          padding: 0.65625rem 1.09375rem;
     }

     .p-button.p-button-sm .p-button-icon {
          font-size: 0.875rem;
     }

     .p-button.p-button-lg {
          font-size: 1.25rem;
          padding: 0.9375rem 1.5625rem;
     }

     .p-button.p-button-lg .p-button-icon {
          font-size: 1.25rem;
     }

     .p-button.p-button-loading-label-only .p-button-label {
          margin-left: 0.5rem;
     }

     .p-button.p-button-loading-label-only .p-button-loading-icon {
          margin-right: 0;
     }

     .p-fluid .p-button {
          width: 100%;
     }

     .p-fluid .p-button-icon-only {
          width: 3rem;
     }

     .p-fluid .p-button-group {
          display: flex;
     }

     .p-fluid .p-button-group .p-button {
          flex: 1;
     }

     .p-button.p-button-secondary,
     .p-button-group.p-button-secondary>.p-button,
     .p-splitbutton.p-button-secondary>.p-button {
          color: #ffffff;
          background: #64748b;
          border: 1px solid #64748b;
     }

     .p-button.p-button-secondary:not(:disabled):hover,
     .p-button-group.p-button-secondary>.p-button:not(:disabled):hover,
     .p-splitbutton.p-button-secondary>.p-button:not(:disabled):hover {
          background: #475569;
          color: #ffffff;
          border-color: #475569;
     }

     .p-button.p-button-secondary:not(:disabled):focus,
     .p-button-group.p-button-secondary>.p-button:not(:disabled):focus,
     .p-splitbutton.p-button-secondary>.p-button:not(:disabled):focus {
          box-shadow: 0 0 0 0.2rem #e2e8f0;
     }

     .p-button.p-button-secondary:not(:disabled):active,
     .p-button-group.p-button-secondary>.p-button:not(:disabled):active,
     .p-splitbutton.p-button-secondary>.p-button:not(:disabled):active {
          background: #334155;
          color: #ffffff;
          border-color: #334155;
     }

     .p-button.p-button-secondary.p-button-outlined,
     .p-button-group.p-button-secondary>.p-button.p-button-outlined,
     .p-splitbutton.p-button-secondary>.p-button.p-button-outlined {
          background-color: transparent;
          color: #64748b;
          border: 1px solid;
     }

     .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover,
     .p-button-group.p-button-secondary>.p-button.p-button-outlined:not(:disabled):hover,
     .p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(:disabled):hover {
          background: rgba(100, 116, 139, 0.04);
          color: #64748b;
          border: 1px solid;
     }

     .p-button.p-button-secondary.p-button-outlined:not(:disabled):active,
     .p-button-group.p-button-secondary>.p-button.p-button-outlined:not(:disabled):active,
     .p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(:disabled):active {
          background: rgba(100, 116, 139, 0.16);
          color: #64748b;
          border: 1px solid;
     }

     .p-button.p-button-secondary.p-button-text,
     .p-button-group.p-button-secondary>.p-button.p-button-text,
     .p-splitbutton.p-button-secondary>.p-button.p-button-text {
          background-color: transparent;
          color: #64748b;
          border-color: transparent;
     }

     .p-button.p-button-secondary.p-button-text:not(:disabled):hover,
     .p-button-group.p-button-secondary>.p-button.p-button-text:not(:disabled):hover,
     .p-splitbutton.p-button-secondary>.p-button.p-button-text:not(:disabled):hover {
          background: rgba(100, 116, 139, 0.04);
          border-color: transparent;
          color: #64748b;
     }

     .p-button.p-button-secondary.p-button-text:not(:disabled):active,
     .p-button-group.p-button-secondary>.p-button.p-button-text:not(:disabled):active,
     .p-splitbutton.p-button-secondary>.p-button.p-button-text:not(:disabled):active {
          background: rgba(100, 116, 139, 0.16);
          border-color: transparent;
          color: #64748b;
     }

     .p-button.p-button-info,
     .p-button-group.p-button-info>.p-button,
     .p-splitbutton.p-button-info>.p-button {
          color: #ffffff;
          background: #0ea5e9;
          border: 1px solid #0ea5e9;
     }

     .p-button.p-button-info:not(:disabled):hover,
     .p-button-group.p-button-info>.p-button:not(:disabled):hover,
     .p-splitbutton.p-button-info>.p-button:not(:disabled):hover {
          background: #0284c7;
          color: #ffffff;
          border-color: #0284c7;
     }

     .p-button.p-button-info:not(:disabled):focus,
     .p-button-group.p-button-info>.p-button:not(:disabled):focus,
     .p-splitbutton.p-button-info>.p-button:not(:disabled):focus {
          box-shadow: 0 0 0 0.2rem #bfdbfe;
     }

     .p-button.p-button-info:not(:disabled):active,
     .p-button-group.p-button-info>.p-button:not(:disabled):active,
     .p-splitbutton.p-button-info>.p-button:not(:disabled):active {
          background: #0369a1;
          color: #ffffff;
          border-color: #0369a1;
     }

     .p-button.p-button-info.p-button-outlined,
     .p-button-group.p-button-info>.p-button.p-button-outlined,
     .p-splitbutton.p-button-info>.p-button.p-button-outlined {
          background-color: transparent;
          color: #0ea5e9;
          border: 1px solid;
     }

     .p-button.p-button-info.p-button-outlined:not(:disabled):hover,
     .p-button-group.p-button-info>.p-button.p-button-outlined:not(:disabled):hover,
     .p-splitbutton.p-button-info>.p-button.p-button-outlined:not(:disabled):hover {
          background: rgba(14, 165, 233, 0.04);
          color: #0ea5e9;
          border: 1px solid;
     }

     .p-button.p-button-info.p-button-outlined:not(:disabled):active,
     .p-button-group.p-button-info>.p-button.p-button-outlined:not(:disabled):active,
     .p-splitbutton.p-button-info>.p-button.p-button-outlined:not(:disabled):active {
          background: rgba(14, 165, 233, 0.16);
          color: #0ea5e9;
          border: 1px solid;
     }

     .p-button.p-button-info.p-button-text,
     .p-button-group.p-button-info>.p-button.p-button-text,
     .p-splitbutton.p-button-info>.p-button.p-button-text {
          background-color: transparent;
          color: #0ea5e9;
          border-color: transparent;
     }

     .p-button.p-button-info.p-button-text:not(:disabled):hover,
     .p-button-group.p-button-info>.p-button.p-button-text:not(:disabled):hover,
     .p-splitbutton.p-button-info>.p-button.p-button-text:not(:disabled):hover {
          background: rgba(14, 165, 233, 0.04);
          border-color: transparent;
          color: #0ea5e9;
     }

     .p-button.p-button-info.p-button-text:not(:disabled):active,
     .p-button-group.p-button-info>.p-button.p-button-text:not(:disabled):active,
     .p-splitbutton.p-button-info>.p-button.p-button-text:not(:disabled):active {
          background: rgba(14, 165, 233, 0.16);
          border-color: transparent;
          color: #0ea5e9;
     }

     .p-button.p-button-success,
     .p-button-group.p-button-success>.p-button,
     .p-splitbutton.p-button-success>.p-button {
          color: #ffffff;
          background: #22c55e;
          border: 1px solid #22c55e;
     }

     .p-button.p-button-success:not(:disabled):hover,
     .p-button-group.p-button-success>.p-button:not(:disabled):hover,
     .p-splitbutton.p-button-success>.p-button:not(:disabled):hover {
          background: #16a34a;
          color: #ffffff;
          border-color: #16a34a;
     }

     .p-button.p-button-success:not(:disabled):focus,
     .p-button-group.p-button-success>.p-button:not(:disabled):focus,
     .p-splitbutton.p-button-success>.p-button:not(:disabled):focus {
          box-shadow: 0 0 0 0.2rem #bbf7d0;
     }

     .p-button.p-button-success:not(:disabled):active,
     .p-button-group.p-button-success>.p-button:not(:disabled):active,
     .p-splitbutton.p-button-success>.p-button:not(:disabled):active {
          background: #15803d;
          color: #ffffff;
          border-color: #15803d;
     }

     .p-button.p-button-success.p-button-outlined,
     .p-button-group.p-button-success>.p-button.p-button-outlined,
     .p-splitbutton.p-button-success>.p-button.p-button-outlined {
          background-color: transparent;
          color: #22c55e;
          border: 1px solid;
     }

     .p-button.p-button-success.p-button-outlined:not(:disabled):hover,
     .p-button-group.p-button-success>.p-button.p-button-outlined:not(:disabled):hover,
     .p-splitbutton.p-button-success>.p-button.p-button-outlined:not(:disabled):hover {
          background: rgba(34, 197, 94, 0.04);
          color: #22c55e;
          border: 1px solid;
     }

     .p-button.p-button-success.p-button-outlined:not(:disabled):active,
     .p-button-group.p-button-success>.p-button.p-button-outlined:not(:disabled):active,
     .p-splitbutton.p-button-success>.p-button.p-button-outlined:not(:disabled):active {
          background: rgba(34, 197, 94, 0.16);
          color: #22c55e;
          border: 1px solid;
     }

     .p-button.p-button-success.p-button-text,
     .p-button-group.p-button-success>.p-button.p-button-text,
     .p-splitbutton.p-button-success>.p-button.p-button-text {
          background-color: transparent;
          color: #22c55e;
          border-color: transparent;
     }

     .p-button.p-button-success.p-button-text:not(:disabled):hover,
     .p-button-group.p-button-success>.p-button.p-button-text:not(:disabled):hover,
     .p-splitbutton.p-button-success>.p-button.p-button-text:not(:disabled):hover {
          background: rgba(34, 197, 94, 0.04);
          border-color: transparent;
          color: #22c55e;
     }

     .p-button.p-button-success.p-button-text:not(:disabled):active,
     .p-button-group.p-button-success>.p-button.p-button-text:not(:disabled):active,
     .p-splitbutton.p-button-success>.p-button.p-button-text:not(:disabled):active {
          background: rgba(34, 197, 94, 0.16);
          border-color: transparent;
          color: #22c55e;
     }

     .p-button.p-button-warning,
     .p-button-group.p-button-warning>.p-button,
     .p-splitbutton.p-button-warning>.p-button {
          color: #ffffff;
          background: #f97316;
          border: 1px solid #f97316;
     }

     .p-button.p-button-warning:not(:disabled):hover,
     .p-button-group.p-button-warning>.p-button:not(:disabled):hover,
     .p-splitbutton.p-button-warning>.p-button:not(:disabled):hover {
          background: #ea580c;
          color: #ffffff;
          border-color: #ea580c;
     }

     .p-button.p-button-warning:not(:disabled):focus,
     .p-button-group.p-button-warning>.p-button:not(:disabled):focus,
     .p-splitbutton.p-button-warning>.p-button:not(:disabled):focus {
          box-shadow: 0 0 0 0.2rem #fde68a;
     }

     .p-button.p-button-warning:not(:disabled):active,
     .p-button-group.p-button-warning>.p-button:not(:disabled):active,
     .p-splitbutton.p-button-warning>.p-button:not(:disabled):active {
          background: #c2410c;
          color: #ffffff;
          border-color: #c2410c;
     }

     .p-button.p-button-warning.p-button-outlined,
     .p-button-group.p-button-warning>.p-button.p-button-outlined,
     .p-splitbutton.p-button-warning>.p-button.p-button-outlined {
          background-color: transparent;
          color: #f97316;
          border: 1px solid;
     }

     .p-button.p-button-warning.p-button-outlined:not(:disabled):hover,
     .p-button-group.p-button-warning>.p-button.p-button-outlined:not(:disabled):hover,
     .p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(:disabled):hover {
          background: rgba(249, 115, 22, 0.04);
          color: #f97316;
          border: 1px solid;
     }

     .p-button.p-button-warning.p-button-outlined:not(:disabled):active,
     .p-button-group.p-button-warning>.p-button.p-button-outlined:not(:disabled):active,
     .p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(:disabled):active {
          background: rgba(249, 115, 22, 0.16);
          color: #f97316;
          border: 1px solid;
     }

     .p-button.p-button-warning.p-button-text,
     .p-button-group.p-button-warning>.p-button.p-button-text,
     .p-splitbutton.p-button-warning>.p-button.p-button-text {
          background-color: transparent;
          color: #f97316;
          border-color: transparent;
     }

     .p-button.p-button-warning.p-button-text:not(:disabled):hover,
     .p-button-group.p-button-warning>.p-button.p-button-text:not(:disabled):hover,
     .p-splitbutton.p-button-warning>.p-button.p-button-text:not(:disabled):hover {
          background: rgba(249, 115, 22, 0.04);
          border-color: transparent;
          color: #f97316;
     }

     .p-button.p-button-warning.p-button-text:not(:disabled):active,
     .p-button-group.p-button-warning>.p-button.p-button-text:not(:disabled):active,
     .p-splitbutton.p-button-warning>.p-button.p-button-text:not(:disabled):active {
          background: rgba(249, 115, 22, 0.16);
          border-color: transparent;
          color: #f97316;
     }

     .p-button.p-button-help,
     .p-button-group.p-button-help>.p-button,
     .p-splitbutton.p-button-help>.p-button {
          color: #ffffff;
          background: #a855f7;
          border: 1px solid #a855f7;
     }

     .p-button.p-button-help:not(:disabled):hover,
     .p-button-group.p-button-help>.p-button:not(:disabled):hover,
     .p-splitbutton.p-button-help>.p-button:not(:disabled):hover {
          background: #9333ea;
          color: #ffffff;
          border-color: #9333ea;
     }

     .p-button.p-button-help:not(:disabled):focus,
     .p-button-group.p-button-help>.p-button:not(:disabled):focus,
     .p-splitbutton.p-button-help>.p-button:not(:disabled):focus {
          box-shadow: 0 0 0 0.2rem #e9d5ff;
     }

     .p-button.p-button-help:not(:disabled):active,
     .p-button-group.p-button-help>.p-button:not(:disabled):active,
     .p-splitbutton.p-button-help>.p-button:not(:disabled):active {
          background: #7e22ce;
          color: #ffffff;
          border-color: #7e22ce;
     }

     .p-button.p-button-help.p-button-outlined,
     .p-button-group.p-button-help>.p-button.p-button-outlined,
     .p-splitbutton.p-button-help>.p-button.p-button-outlined {
          background-color: transparent;
          color: #a855f7;
          border: 1px solid;
     }

     .p-button.p-button-help.p-button-outlined:not(:disabled):hover,
     .p-button-group.p-button-help>.p-button.p-button-outlined:not(:disabled):hover,
     .p-splitbutton.p-button-help>.p-button.p-button-outlined:not(:disabled):hover {
          background: rgba(168, 85, 247, 0.04);
          color: #a855f7;
          border: 1px solid;
     }

     .p-button.p-button-help.p-button-outlined:not(:disabled):active,
     .p-button-group.p-button-help>.p-button.p-button-outlined:not(:disabled):active,
     .p-splitbutton.p-button-help>.p-button.p-button-outlined:not(:disabled):active {
          background: rgba(168, 85, 247, 0.16);
          color: #a855f7;
          border: 1px solid;
     }

     .p-button.p-button-help.p-button-text,
     .p-button-group.p-button-help>.p-button.p-button-text,
     .p-splitbutton.p-button-help>.p-button.p-button-text {
          background-color: transparent;
          color: #a855f7;
          border-color: transparent;
     }

     .p-button.p-button-help.p-button-text:not(:disabled):hover,
     .p-button-group.p-button-help>.p-button.p-button-text:not(:disabled):hover,
     .p-splitbutton.p-button-help>.p-button.p-button-text:not(:disabled):hover {
          background: rgba(168, 85, 247, 0.04);
          border-color: transparent;
          color: #a855f7;
     }

     .p-button.p-button-help.p-button-text:not(:disabled):active,
     .p-button-group.p-button-help>.p-button.p-button-text:not(:disabled):active,
     .p-splitbutton.p-button-help>.p-button.p-button-text:not(:disabled):active {
          background: rgba(168, 85, 247, 0.16);
          border-color: transparent;
          color: #a855f7;
     }

     .p-button.p-button-danger,
     .p-button-group.p-button-danger>.p-button,
     .p-splitbutton.p-button-danger>.p-button {
          color: #ffffff;
          background: #ef4444;
          border: 1px solid #ef4444;
     }

     .p-button.p-button-danger:not(:disabled):hover,
     .p-button-group.p-button-danger>.p-button:not(:disabled):hover,
     .p-splitbutton.p-button-danger>.p-button:not(:disabled):hover {
          background: #dc2626;
          color: #ffffff;
          border-color: #dc2626;
     }

     .p-button.p-button-danger:not(:disabled):focus,
     .p-button-group.p-button-danger>.p-button:not(:disabled):focus,
     .p-splitbutton.p-button-danger>.p-button:not(:disabled):focus {
          box-shadow: 0 0 0 0.2rem #fecaca;
     }

     .p-button.p-button-danger:not(:disabled):active,
     .p-button-group.p-button-danger>.p-button:not(:disabled):active,
     .p-splitbutton.p-button-danger>.p-button:not(:disabled):active {
          background: #b91c1c;
          color: #ffffff;
          border-color: #b91c1c;
     }

     .p-button.p-button-danger.p-button-outlined,
     .p-button-group.p-button-danger>.p-button.p-button-outlined,
     .p-splitbutton.p-button-danger>.p-button.p-button-outlined {
          background-color: transparent;
          color: #ef4444;
          border: 1px solid;
     }

     .p-button.p-button-danger.p-button-outlined:not(:disabled):hover,
     .p-button-group.p-button-danger>.p-button.p-button-outlined:not(:disabled):hover,
     .p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(:disabled):hover {
          background: rgba(239, 68, 68, 0.04);
          color: #ef4444;
          border: 1px solid;
     }

     .p-button.p-button-danger.p-button-outlined:not(:disabled):active,
     .p-button-group.p-button-danger>.p-button.p-button-outlined:not(:disabled):active,
     .p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(:disabled):active {
          background: rgba(239, 68, 68, 0.16);
          color: #ef4444;
          border: 1px solid;
     }

     .p-button.p-button-danger.p-button-text,
     .p-button-group.p-button-danger>.p-button.p-button-text,
     .p-splitbutton.p-button-danger>.p-button.p-button-text {
          background-color: transparent;
          color: #ef4444;
          border-color: transparent;
     }

     .p-button.p-button-danger.p-button-text:not(:disabled):hover,
     .p-button-group.p-button-danger>.p-button.p-button-text:not(:disabled):hover,
     .p-splitbutton.p-button-danger>.p-button.p-button-text:not(:disabled):hover {
          background: rgba(239, 68, 68, 0.04);
          border-color: transparent;
          color: #ef4444;
     }

     .p-button.p-button-danger.p-button-text:not(:disabled):active,
     .p-button-group.p-button-danger>.p-button.p-button-text:not(:disabled):active,
     .p-splitbutton.p-button-danger>.p-button.p-button-text:not(:disabled):active {
          background: rgba(239, 68, 68, 0.16);
          border-color: transparent;
          color: #ef4444;
     }

     .p-button.p-button-link {
          color: #005693;
          background: transparent;
          border: transparent;
     }

     .p-button.p-button-link:not(:disabled):hover {
          background: transparent;
          color: #005693;
          border-color: transparent;
     }

     .p-button.p-button-link:not(:disabled):hover .p-button-label {
          text-decoration: underline;
     }

     .p-button.p-button-link:not(:disabled):focus {
          background: transparent;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: transparent;
     }

     .p-button.p-button-link:not(:disabled):active {
          background: transparent;
          color: #005693;
          border-color: transparent;
     }

     .p-speeddial-button.p-button.p-button-icon-only {
          width: 4rem;
          height: 4rem;
     }

     .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
          font-size: 1.3rem;
     }

     .p-speeddial-button.p-button.p-button-icon-only .p-icon {
          width: 1.3rem;
          height: 1.3rem;
     }

     .p-speeddial-list {
          outline: 0 none;
     }

     .p-speeddial-item.p-focus>.p-speeddial-action {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-speeddial-action {
          width: 3rem;
          height: 3rem;
          background: var(--text-color);
          color: #fff;
     }

     .p-speeddial-action:hover {
          background: #022354;
          color: #fff;
     }

     .p-speeddial-direction-up .p-speeddial-item {
          margin: 0.25rem 0;
     }

     .p-speeddial-direction-up .p-speeddial-item:first-child {
          margin-bottom: 0.5rem;
     }

     .p-speeddial-direction-down .p-speeddial-item {
          margin: 0.25rem 0;
     }

     .p-speeddial-direction-down .p-speeddial-item:first-child {
          margin-top: 0.5rem;
     }

     .p-speeddial-direction-left .p-speeddial-item {
          margin: 0 0.25rem;
     }

     .p-speeddial-direction-left .p-speeddial-item:first-child {
          margin-right: 0.5rem;
     }

     .p-speeddial-direction-right .p-speeddial-item {
          margin: 0 0.25rem;
     }

     .p-speeddial-direction-right .p-speeddial-item:first-child {
          margin-left: 0.5rem;
     }

     .p-speeddial-circle .p-speeddial-item,
     .p-speeddial-semi-circle .p-speeddial-item,
     .p-speeddial-quarter-circle .p-speeddial-item {
          margin: 0;
     }

     .p-speeddial-circle .p-speeddial-item:first-child,
     .p-speeddial-circle .p-speeddial-item:last-child,
     .p-speeddial-semi-circle .p-speeddial-item:first-child,
     .p-speeddial-semi-circle .p-speeddial-item:last-child,
     .p-speeddial-quarter-circle .p-speeddial-item:first-child,
     .p-speeddial-quarter-circle .p-speeddial-item:last-child {
          margin: 0;
     }

     .p-speeddial-mask {
          background-color: rgba(0, 0, 0, 0.4);
     }

     .p-splitbutton {
          border-radius: 4px;
     }

     .p-splitbutton.p-button-rounded {
          border-radius: 2rem;
     }

     .p-splitbutton.p-button-rounded>.p-button {
          border-radius: 2rem;
     }

     .p-splitbutton.p-button-raised {
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
     }

     .p-carousel .p-carousel-content .p-carousel-prev,
     .p-carousel .p-carousel-content .p-carousel-next {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          margin: 0.5rem;
     }

     .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
     .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
     .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-carousel .p-carousel-indicators {
          padding: 1rem;
     }

     .p-carousel .p-carousel-indicators .p-carousel-indicator {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
     }

     .p-carousel .p-carousel-indicators .p-carousel-indicator button {
          background-color: #d1d5db;
          width: 2rem;
          height: 0.5rem;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius: 0;
     }

     .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
          background: #9ca3af;
     }

     .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
          background: #EFF6FF;
          color: #005693;
     }

     .p-datatable .p-paginator-top {
          border-width: 0 0 1px 0;
          border-radius: 0;
     }

     .p-datatable .p-paginator-bottom {
          border-width: 0 0 1px 0;
          border-radius: 0;
     }

     .p-datatable .p-datatable-header {
          background: transparent;
          color: #374151;
          border: 0px solid #e5e7eb;
          border-width: 0px 0 1px 0;
          padding: 1rem 1rem;
          font-weight: 700;
     }

     .p-datatable .p-datatable-footer {
          background: transparent;
          color: #374151;
          border: 0px solid #e5e7eb;
          border-width: 0 0 0px 0;
          padding: 1rem 1rem;
          font-weight: 700;
     }

     .p-datatable .p-datatable-thead>tr>th {
          text-align: left;
          padding: 1rem 1rem;
          border: 1px solid #e5e7eb;
          border-width: 0 0 1px 0;
          font-weight: 700;
          color: #374151;
          background: #E5F1FF;
          transition: box-shadow 0.2s;
     }

     .p-datatable .p-datatable-tfoot>tr>td {
          text-align: left;
          padding: 1rem 1rem;
          border: 1px solid #e5e7eb;
          border-width: 0 0 1px 0;
          font-weight: 700;
          color: #374151;
          background: #f9fafb;
     }

     .p-datatable .p-sortable-column .p-sortable-column-icon {
          color: #374151;
          margin-left: 0.5rem;
     }

     .p-datatable .p-sortable-column .p-sortable-column-badge {
          border-radius: 50%;
          height: 1.143rem;
          min-width: 1.143rem;
          line-height: 1.143rem;
          color: #005693;
          background: #EFF6FF;
          margin-left: 0.5rem;
     }

     .p-datatable .p-sortable-column:not(.p-highlight):hover {
          background: #f3f4f6;
          color: #374151;
     }

     .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
          color: #374151;
     }

     .p-datatable .p-sortable-column.p-highlight {
          background: #EFF6FF;
          color: var(--text-color);
     }

     .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
          color: var(--text-color);
     }

     .p-datatable .p-sortable-column.p-highlight:hover {
          background: #EFF6FF;
          color: var(--text-color);
     }

     .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
          color: var(--text-color);
     }

     .p-datatable .p-sortable-column:focus-visible {
          box-shadow: inset 0 0 0 0.15rem #BFDBFE;
          outline: 0 none;
     }

     .p-datatable .p-datatable-tbody>tr {
          background: #ffffff;
          color: var(--text-color);
          transition: box-shadow 0.2s;
     }

     .p-datatable .p-datatable-tbody>tr>td {
          text-align: left;
          border: 1px solid #e5e7eb;
          border-width: 0 0 1px 0;
          padding: 1rem 1rem;
     }

     .p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus-visible,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus-visible,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus-visible,
     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
          margin-right: 0.5rem;
     }

     .p-datatable .p-datatable-tbody>tr:focus-visible {
          outline: 0.15rem solid #BFDBFE;
          outline-offset: -0.15rem;
     }

     .p-datatable .p-datatable-tbody>tr.p-highlight {
          background: #EFF6FF;
          color: #005693;
     }

     .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
          box-shadow: inset 0 2px 0 0 #EFF6FF;
     }

     .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
          box-shadow: inset 0 -2px 0 0 #EFF6FF;
     }

     .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-datatable .p-column-resizer-helper {
          background: #005693;
     }

     .p-datatable .p-datatable-scrollable-header,
     .p-datatable .p-datatable-scrollable-footer {
          background: #f9fafb;
     }

     .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-thead,
     .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-datatable-table>.p-datatable-tfoot,
     .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table>.p-datatable-thead,
     .p-datatable.p-datatable-scrollable>.p-datatable-wrapper>.p-scroller-viewport>.p-scroller>.p-datatable-table>.p-datatable-tfoot {
          background-color: #f9fafb;
     }

     .p-datatable .p-datatable-loading-icon {
          font-size: 2rem;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-header {
          border-width: 1px 1px 0 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-footer {
          border-width: 0 1px 1px 1px;
     }

     .p-datatable.p-datatable-gridlines .p-paginator-top {
          border-width: 0 1px 0 1px;
     }

     .p-datatable.p-datatable-gridlines .p-paginator-bottom {
          border-width: 0 1px 1px 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
          border-width: 1px 0 1px 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:last-child {
          border-width: 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
          border-width: 1px 0 0 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child {
          border-width: 1px 1px 0 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td {
          border-width: 1px 0 1px 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr:last-child>td:last-child {
          border-width: 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td {
          border-width: 1px 0 1px 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td:last-child {
          border-width: 1px 1px 1px 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td {
          border-width: 0 0 1px 1px;
     }

     .p-datatable.p-datatable-gridlines .p-datatable-thead+.p-datatable-tfoot>tr>td:last-child {
          border-width: 0 1px 1px 1px;
     }

     .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
          border-width: 0 0 1px 1px;
     }

     .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td:last-child {
          border-width: 0 1px 1px 1px;
     }

     .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td {
          border-width: 0 0 0 1px;
     }

     .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody>tr:last-child>td:last-child {
          border-width: 0 1px 0 1px;
     }

     .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
          background: #f8f8fa;
     }

     .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight {
          background: #EFF6FF;
          color: #005693;
     }

     .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler {
          color: #005693;
     }

     .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler:hover {
          color: #005693;
     }

     .p-datatable.p-datatable-sm .p-datatable-header {
          padding: 0.5rem 0.5rem;
     }

     .p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
          padding: 0.5rem 0.5rem;
     }

     .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
          padding: 0.5rem 0.5rem;
     }

     .p-datatable.p-datatable-sm .p-datatable-tfoot>tr>td {
          padding: 0.5rem 0.5rem;
     }

     .p-datatable.p-datatable-sm .p-datatable-footer {
          padding: 0.5rem 0.5rem;
     }

     .p-datatable.p-datatable-lg .p-datatable-header {
          padding: 1.25rem 1.25rem;
     }

     .p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
          padding: 1.25rem 1.25rem;
     }

     .p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
          padding: 1.25rem 1.25rem;
     }

     .p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
          padding: 1.25rem 1.25rem;
     }

     .p-datatable.p-datatable-lg .p-datatable-footer {
          padding: 1.25rem 1.25rem;
     }

     .p-dataview .p-paginator-top {
          border-width: 0 0 1px 0;
          border-radius: 0;
     }

     .p-dataview .p-paginator-bottom {
          border-width: 0 0 1px 0;
          border-radius: 0;
     }

     .p-dataview .p-dataview-header {
          background: #f9fafb;
          color: #374151;
          border: 1px solid #e5e7eb;
          border-width: 1px 0 1px 0;
          padding: 1rem 1rem;
          font-weight: 700;
     }

     .p-dataview .p-dataview-content {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          padding: 0;
     }

     .p-dataview .p-dataview-footer {
          /* background: #f9fafb; */
          background: transparent;
          color: #374151;
          /* border: 1px solid #e5e7eb; */
          border-width: 0 0 1px 0;
          padding: 1rem 1rem;
          font-weight: 700;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-dataview .p-dataview-loading-icon {
          font-size: 2rem;
     }

     .p-dataview .p-dataview-emptymessage {
          padding: 1.25rem;
     }

     .p-column-filter-row .p-column-filter-menu-button,
     .p-column-filter-row .p-column-filter-clear-button {
          margin-left: 0.5rem;
     }

     .p-column-filter-menu-button {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-column-filter-menu-button:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-column-filter-menu-button.p-column-filter-menu-button-open,
     .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
          background: #f3f4f6;
          color: #374151;
     }

     .p-column-filter-menu-button.p-column-filter-menu-button-active,
     .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
          background: #EFF6FF;
          color: #005693;
     }

     .p-column-filter-menu-button:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-column-filter-clear-button {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-column-filter-clear-button:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-column-filter-clear-button:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-column-filter-overlay {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          min-width: 12.5rem;
     }

     .p-column-filter-overlay .p-column-filter-row-items {
          padding: 0.75rem 0;
     }

     .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
          margin: 0;
          padding: 0.75rem 1.25rem;
          border: 0 none;
          color: var(--text-color);
          background: transparent;
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:first-child {
          margin-top: 0;
     }

     .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem #BFDBFE;
     }

     .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
          border-top: 1px solid #e5e7eb;
          margin: 0.25rem 0;
     }

     .p-column-filter-overlay-menu .p-column-filter-operator {
          padding: 0.75rem 1.25rem;
          border-bottom: 1px solid #e5e7eb;
          color: #374151;
          background: #f9fafb;
          margin: 0;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-column-filter-overlay-menu .p-column-filter-constraint {
          padding: 1.25rem;
          border-bottom: 1px solid #e5e7eb;
     }

     .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
          margin-bottom: 0.5rem;
     }

     .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
          margin-top: 0.5rem;
     }

     .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
          border-bottom: 0 none;
     }

     .p-column-filter-overlay-menu .p-column-filter-add-rule {
          padding: 0.75rem 1.25rem;
     }

     .p-column-filter-overlay-menu .p-column-filter-buttonbar {
          padding: 1.25rem;
     }

     .p-orderlist .p-orderlist-controls {
          padding: 1.25rem;
     }

     .p-orderlist .p-orderlist-controls .p-button {
          margin-bottom: 0.5rem;
     }

     .p-orderlist .p-orderlist-list-container {
          background: #ffffff;
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          outline-color: transparent;
     }

     .p-orderlist .p-orderlist-list-container.p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-orderlist .p-orderlist-header {
          color: #374151;
          padding: 1.25rem;
          font-weight: 700;
     }

     .p-orderlist .p-orderlist-header .p-orderlist-title {
          font-weight: 700;
     }

     .p-orderlist .p-orderlist-filter-container {
          padding: 1.25rem;
          background: #ffffff;
          border: 1px solid #e5e7eb;
          border-bottom: 0 none;
     }

     .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
          padding-right: 1.75rem;
     }

     .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
          right: 0.75rem;
          color: #6b7280;
     }

     .p-orderlist .p-orderlist-list {
          color: var(--text-color);
          padding: 0.75rem 0;
          outline: 0 none;
     }

     .p-orderlist .p-orderlist-list:not(:first-child) {
          border-top: 1px solid #e5e7eb;
     }

     .p-orderlist .p-orderlist-list .p-orderlist-item {
          padding: 0.75rem 1.25rem;
          margin: 0;
          border: 0 none;
          color: var(--text-color);
          background: transparent;
          transition: box-shadow 0.2s;
     }

     .p-orderlist .p-orderlist-list .p-orderlist-item:first-child {
          margin-top: 0;
     }

     .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
          color: #005693;
          background: #EFF6FF;
     }

     .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-orderlist .p-orderlist-list .p-orderlist-empty-message {
          padding: 0.75rem 1.25rem;
          color: var(--text-color);
     }

     .p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
          background: #f8f8fa;
     }

     .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
          background: #f3f4f6;
     }

     .p-orderlist-item.cdk-drag-preview {
          padding: 0.75rem 1.25rem;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border: 0 none;
          color: var(--text-color);
          background: #ffffff;
          margin: 0;
     }

     .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-organizationchart .p-organizationchart-node-content.p-highlight {
          background: #EFF6FF;
          color: #005693;
     }

     .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
          color: #70aeff;
     }

     .p-organizationchart .p-organizationchart-line-down {
          background: #e5e7eb;
     }

     .p-organizationchart .p-organizationchart-line-left {
          border-right: 1px solid #e5e7eb;
          border-color: #e5e7eb;
     }

     .p-organizationchart .p-organizationchart-line-top {
          border-top: 1px solid #e5e7eb;
          border-color: #e5e7eb;
     }

     .p-organizationchart .p-organizationchart-node-content {
          border: 1px solid #e5e7eb;
          background: #ffffff;
          color: var(--text-color);
          padding: 1.25rem;
     }

     .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
          background: inherit;
          color: inherit;
          border-radius: 50%;
     }

     .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-paginator {
          background: #ffffff;
          color: #6b7280;
          border: solid #f3f4f6;
          border-width: 0;
          padding: 0.5rem 1rem;
          border-radius: 4px;
     }

     .p-paginator .p-paginator-first,
     .p-paginator .p-paginator-prev,
     .p-paginator .p-paginator-next,
     .p-paginator .p-paginator-last {
          background-color: transparent;
          border: 0 none;
          color: #6b7280;
          min-width: 3rem;
          height: 3rem;
          margin: 0.143rem;
          transition: box-shadow 0.2s;
          border-radius: 50%;
     }

     .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
     .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
     .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
     .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
          background: #f3f4f6;
          border-color: transparent;
          color: #374151;
     }

     .p-paginator .p-paginator-first {
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
     }

     .p-paginator .p-paginator-last {
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
     }

     .p-paginator .p-dropdown {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          height: 3rem;
     }

     .p-paginator .p-dropdown .p-dropdown-label {
          padding-right: 0;
     }

     .p-paginator .p-paginator-page-input {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
     }

     .p-paginator .p-paginator-page-input .p-inputtext {
          max-width: 3rem;
     }

     .p-paginator .p-paginator-current {
          background-color: transparent;
          border: 0 none;
          color: #6b7280;
          min-width: 3rem;
          height: 3rem;
          margin: 0.143rem;
          padding: 0 0.5rem;
     }

     .p-paginator .p-paginator-pages .p-paginator-page {
          background-color: transparent;
          border: 0 none;
          color: #6b7280;
          min-width: 3rem;
          height: 3rem;
          margin: 0.143rem;
          transition: box-shadow 0.2s;
          border-radius: 50%;
     }

     .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
          background: #EFF6FF;
          border-color: #EFF6FF;
          color: #005693;
     }

     .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
          background: #f3f4f6;
          border-color: transparent;
          color: #374151;
     }

     .p-picklist .p-picklist-buttons {
          padding: 1.25rem;
     }

     .p-picklist .p-picklist-buttons .p-button {
          margin-bottom: 0.5rem;
     }

     .p-picklist .p-picklist-list-wrapper {
          background: #ffffff;
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
          outline-color: transparent;
     }

     .p-picklist .p-picklist-list-wrapper.p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
          border-color: #005693;
     }

     .p-picklist .p-picklist-header {
          color: #374151;
          padding: 1.25rem;
          font-weight: 700;
     }

     .p-picklist .p-picklist-header .p-picklist-title {
          font-weight: 700;
     }

     .p-picklist .p-picklist-filter-container {
          padding: 1.25rem;
          background: #ffffff;
          border: 1px solid #e5e7eb;
          border-bottom: 0 none;
     }

     .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
          padding-right: 1.75rem;
     }

     .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
          right: 0.75rem;
          color: #6b7280;
     }

     .p-picklist .p-picklist-list {
          color: var(--text-color);
          padding: 0.75rem 0;
          outline: 0 none;
     }

     .p-picklist .p-picklist-list:not(:first-child) {
          border-top: 1px solid #e5e7eb;
     }

     .p-picklist .p-picklist-list .p-picklist-item {
          padding: 0.75rem 1.25rem;
          margin: 0;
          border: 0 none;
          color: var(--text-color);
          background: transparent;
          transition: box-shadow 0.2s;
     }

     .p-picklist .p-picklist-list .p-picklist-item:first-child {
          margin-top: 0;
     }

     .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-picklist .p-picklist-list .p-picklist-item.p-focus {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
          color: var(--text-color);
          background: #EFF6FF;
     }

     .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-picklist .p-picklist-list .p-picklist-empty-message {
          padding: 0.75rem 1.25rem;
          color: var(--text-color);
     }

     .p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even) {
          background: #f8f8fa;
     }

     .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(even):hover {
          background: #f3f4f6;
     }

     .p-picklist-item.cdk-drag-preview {
          padding: 0.75rem 1.25rem;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border: 0 none;
          color: var(--text-color);
          background: #ffffff;
          margin: 0;
     }

     .p-timeline .p-timeline-event-marker {
          border: 2px solid #005693;
          border-radius: 50%;
          width: 1rem;
          height: 1rem;
          background-color: #ffffff;
     }

     .p-timeline .p-timeline-event-connector {
          background-color: #e5e7eb;
     }

     .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
     .p-timeline.p-timeline-vertical .p-timeline-event-content {
          padding: 0 1rem;
     }

     .p-timeline.p-timeline-vertical .p-timeline-event-connector {
          width: 2px;
     }

     .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
     .p-timeline.p-timeline-horizontal .p-timeline-event-content {
          padding: 1rem 0;
     }

     .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
          height: 2px;
     }

     .p-tree {
          border: 1px solid #e5e7eb;
          background: #ffffff;
          color: var(--text-color);
          padding: 1.25rem;
          border-radius: 4px;
     }

     .p-tree .p-tree-container .p-treenode {
          padding: 0.143rem;
          outline: 0 none;
     }

     .p-tree .p-tree-container .p-treenode:focus>.p-treenode-content {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem #BFDBFE;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content {
          border-radius: 4px;
          transition: box-shadow 0.2s;
          padding: 0.5rem;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
          margin-right: 0.5rem;
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
          margin-right: 0.5rem;
          color: #6b7280;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
          margin-right: 0.5rem;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
          color: var(--text-color);
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box {
          background-color: #f3f4f6;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
          background: #005693;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box:hover {
          background-color: #f3f4f6;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
          background: #005693;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
          background: #EFF6FF;
          color: #005693;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
     .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
          color: #005693;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
     .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
          color: #005693;
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-tree .p-tree-filter-container {
          margin-bottom: 0.5rem;
     }

     .p-tree .p-tree-filter-container .p-tree-filter {
          width: 100%;
          padding-right: 1.75rem;
     }

     .p-tree .p-tree-filter-container .p-tree-filter-icon {
          right: 0.75rem;
          color: #6b7280;
     }

     .p-tree .p-treenode-children {
          padding: 0 0 0 1rem;
     }

     .p-tree .p-tree-loading-icon {
          font-size: 2rem;
     }

     .p-tree .p-tree-loading-icon.p-icon {
          width: 2rem;
          height: 2rem;
     }

     .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
          background-color: #8cbeff;
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
          border-radius: 4px;
          border: 1px solid #e5e7eb;
          background-color: #ffffff;
          color: var(--text-color);
          padding: 0.5rem;
          transition: box-shadow 0.2s;
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
          background-color: #EFF6FF;
          color: #005693;
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
          color: #005693;
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
          margin-right: 0.5rem;
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
          color: #6b7280;
          margin-right: 0.5rem;
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
          margin-right: 0.5rem;
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
          background-color: inherit;
          color: inherit;
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-treetable .p-paginator-top {
          border-width: 0 0 1px 0;
          border-radius: 0;
     }

     .p-treetable .p-paginator-bottom {
          border-width: 0 0 1px 0;
          border-radius: 0;
     }

     .p-treetable .p-treetable-header {
          background: #f9fafb;
          color: #374151;
          border: 1px solid #e5e7eb;
          border-width: 1px 0 1px 0;
          padding: 1rem 1rem;
          font-weight: 700;
     }

     .p-treetable .p-treetable-footer {
          background: #f9fafb;
          color: #374151;
          border: 1px solid #e5e7eb;
          border-width: 0 0 1px 0;
          padding: 1rem 1rem;
          font-weight: 700;
     }

     .p-treetable .p-treetable-thead>tr>th {
          text-align: left;
          padding: 1rem 1rem;
          border: 1px solid #e5e7eb;
          border-width: 0 0 1px 0;
          font-weight: 700;
          color: #374151;
          background: #f9fafb;
          transition: box-shadow 0.2s;
     }

     .p-treetable .p-treetable-tfoot>tr>td {
          text-align: left;
          padding: 1rem 1rem;
          border: 1px solid #e5e7eb;
          border-width: 0 0 1px 0;
          font-weight: 700;
          color: #374151;
          background: #f9fafb;
     }

     .p-treetable .p-sortable-column {
          outline-color: #BFDBFE;
     }

     .p-treetable .p-sortable-column .p-sortable-column-icon {
          color: #374151;
          margin-left: 0.5rem;
     }

     .p-treetable .p-sortable-column .p-sortable-column-badge {
          border-radius: 50%;
          height: 1.143rem;
          min-width: 1.143rem;
          line-height: 1.143rem;
          color: #005693;
          background: #EFF6FF;
          margin-left: 0.5rem;
     }

     .p-treetable .p-sortable-column:not(.p-highlight):hover {
          background: #f3f4f6;
          color: #374151;
     }

     .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
          color: #374151;
     }

     .p-treetable .p-sortable-column.p-highlight {
          background: #EFF6FF;
          color: #005693;
     }

     .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
          color: #005693;
     }

     .p-treetable .p-treetable-tbody>tr {
          background: #ffffff;
          color: var(--text-color);
          transition: box-shadow 0.2s;
     }

     .p-treetable .p-treetable-tbody>tr>td {
          text-align: left;
          border: 1px solid #e5e7eb;
          border-width: 0 0 1px 0;
          padding: 1rem 1rem;
     }

     .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          margin-right: 0.5rem;
     }

     .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler.p-icon {
          width: 2rem;
          height: 2rem;
     }

     .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox {
          margin-right: 0.5rem;
     }

     .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
          color: var(--text-color);
     }

     .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box {
          background-color: #f3f4f6;
     }

     .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
          background: #005693;
     }

     .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box:hover {
          background-color: #f3f4f6;
     }

     .p-treetable .p-treetable-tbody>tr>td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
          background: #005693;
     }

     .p-treetable .p-treetable-tbody>tr:focus-visible {
          outline: 0.15rem solid #BFDBFE;
          outline-offset: -0.15rem;
     }

     .p-treetable .p-treetable-tbody>tr.p-highlight {
          background: #EFF6FF;
          color: #005693;
     }

     .p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler {
          color: #005693;
     }

     .p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler:hover {
          color: #005693;
     }

     .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler {
          color: var(--text-color);
     }

     .p-treetable .p-column-resizer-helper {
          background: #005693;
     }

     .p-treetable .p-treetable-scrollable-header,
     .p-treetable .p-treetable-scrollable-footer {
          background: #f9fafb;
     }

     .p-treetable .p-treetable-loading-icon {
          font-size: 2rem;
     }

     .p-treetable .p-treetable-loading-icon.p-icon {
          width: 2rem;
          height: 2rem;
     }

     .p-treetable.p-treetable-gridlines .p-datatable-header {
          border-width: 1px 1px 0 1px;
     }

     .p-treetable.p-treetable-gridlines .p-treetable-footer {
          border-width: 0 1px 1px 1px;
     }

     .p-treetable.p-treetable-gridlines .p-treetable-top {
          border-width: 0 1px 0 1px;
     }

     .p-treetable.p-treetable-gridlines .p-treetable-bottom {
          border-width: 0 1px 1px 1px;
     }

     .p-treetable.p-treetable-gridlines .p-treetable-thead>tr>th {
          border-width: 1px;
     }

     .p-treetable.p-treetable-gridlines .p-treetable-tbody>tr>td {
          border-width: 1px;
     }

     .p-treetable.p-treetable-gridlines .p-treetable-tfoot>tr>td {
          border-width: 1px;
     }

     .p-treetable.p-treetable-sm .p-treetable-header {
          padding: 0.875rem 0.875rem;
     }

     .p-treetable.p-treetable-sm .p-treetable-thead>tr>th {
          padding: 0.5rem 0.5rem;
     }

     .p-treetable.p-treetable-sm .p-treetable-tbody>tr>td {
          padding: 0.5rem 0.5rem;
     }

     .p-treetable.p-treetable-sm .p-treetable-tfoot>tr>td {
          padding: 0.5rem 0.5rem;
     }

     .p-treetable.p-treetable-sm .p-treetable-footer {
          padding: 0.5rem 0.5rem;
     }

     .p-treetable.p-treetable-lg .p-treetable-header {
          padding: 1.25rem 1.25rem;
     }

     .p-treetable.p-treetable-lg .p-treetable-thead>tr>th {
          padding: 1.25rem 1.25rem;
     }

     .p-treetable.p-treetable-lg .p-treetable-tbody>tr>td {
          padding: 1.25rem 1.25rem;
     }

     .p-treetable.p-treetable-lg .p-treetable-tfoot>tr>td {
          padding: 1.25rem 1.25rem;
     }

     .p-treetable.p-treetable-lg .p-treetable-footer {
          padding: 1.25rem 1.25rem;
     }

     .p-virtualscroller .p-virtualscroller-header {
          background: #f9fafb;
          color: #374151;
          border: 1px solid #e5e7eb;
          border-width: 1px 0 1px 0;
          padding: 1rem 1rem;
          font-weight: 700;
     }

     .p-virtualscroller .p-virtualscroller-content {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          padding: 0;
     }

     .p-virtualscroller .p-virtualscroller-footer {
          background: #f9fafb;
          color: #374151;
          border: 1px solid #e5e7eb;
          border-width: 0 0 1px 0;
          padding: 1rem 1rem;
          font-weight: 700;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-accordion .p-accordion-header .p-accordion-header-link {
          padding: 1.25rem;
          border: 1px solid #e5e7eb;
          color: #6b7280;
          background: #f9fafb;
          font-weight: 700;
          border-radius: 4px;
          transition: box-shadow 0.2s;
     }

     .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
          margin-right: 0.5rem;
     }

     .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #BFDBFE;
     }

     .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
          background: #f3f4f6;
          border-color: #e5e7eb;
          color: #374151;
     }

     .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
          background: #f9fafb;
          border-color: #e5e7eb;
          color: #374151;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
     }

     .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
          border-color: #e5e7eb;
          background: #f3f4f6;
          color: #374151;
     }

     .p-accordion .p-accordion-content {
          padding: 1.25rem;
          border: 1px solid #e5e7eb;
          background: #ffffff;
          color: var(--text-color);
          border-top: 0;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-accordion p-accordiontab .p-accordion-tab {
          margin-bottom: 4px;
     }

     .p-card {
          background: #ffffff;
          color: var(--text-color);
          box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
          border-radius: 4px;
     }

     .p-card .p-card-body {
          padding: 1.25rem;
     }

     .p-card .p-card-title {
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 0.4rem;
          color: var(--text-color);
     }

     .p-card .p-card-subtitle {
          font-weight: 400;
          margin-bottom: 0.5rem;
          color: #6b7280;
     }

     .p-card .p-card-content {
          padding: 1.25rem 0;
     }

     .p-card .p-card-footer {
          padding: 1.25rem 0 0 0;
     }

     .p-divider .p-divider-content {
          background-color: #ffffff;
     }

     .p-divider.p-divider-horizontal {
          margin: 1.25rem 0;
          padding: 0 1.25rem;
     }

     .p-divider.p-divider-horizontal:before {
          border-top: 1px #e5e7eb;
     }

     .p-divider.p-divider-horizontal .p-divider-content {
          padding: 0 0.5rem;
     }

     .p-divider.p-divider-vertical {
          margin: 0 1.25rem;
          padding: 1.25rem 0;
     }

     .p-divider.p-divider-vertical:before {
          border-left: 1px #e5e7eb;
     }

     .p-divider.p-divider-vertical .p-divider-content {
          padding: 0.5rem 0;
     }

     .p-fieldset {
          border: 1px solid #e5e7eb;
          background: #ffffff;
          color: var(--text-color);
          border-radius: 4px;
     }

     .p-fieldset .p-fieldset-legend {
          padding: 1.25rem;
          border: 1px solid #e5e7eb;
          color: #374151;
          background: #f9fafb;
          font-weight: 700;
          border-radius: 4px;
     }

     .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
          padding: 0;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
          padding: 1.25rem;
          color: #374151;
          border-radius: 4px;
          transition: box-shadow 0.2s;
     }

     .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
          margin-right: 0.5rem;
     }

     .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
          background: #f3f4f6;
          border-color: #e5e7eb;
          color: #374151;
     }

     .p-fieldset .p-fieldset-content {
          padding: 1.25rem;
     }

     .p-panel .p-panel-header {
          border: 1px solid #e5e7eb;
          padding: 1.25rem;
          background: #f9fafb;
          color: #374151;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-panel .p-panel-header .p-panel-title {
          font-weight: 700;
     }

     .p-panel .p-panel-header .p-panel-header-icon {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-panel.p-panel-toggleable .p-panel-header {
          padding: 0.75rem 1.25rem;
     }

     .p-panel .p-panel-content {
          padding: 1.25rem;
          border: 1px solid #e5e7eb;
          background: #ffffff;
          color: var(--text-color);
          border-top: 0 none;
     }

     .p-panel .p-panel-content:last-child {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-panel .p-panel-footer {
          padding: 0.75rem 1.25rem;
          border: 1px solid #e5e7eb;
          background: #ffffff;
          color: var(--text-color);
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          border-top: 0 none;
     }

     .p-panel .p-panel-icons-end {
          order: 2;
          margin-left: auto;
     }

     .p-panel .p-panel-icons-start {
          order: 0;
          margin-right: 0.5rem;
     }

     .p-panel .p-panel-icons-center {
          order: 2;
          width: 100%;
          text-align: center;
     }

     .p-scrollpanel .p-scrollpanel-bar {
          background: #f9fafb;
          border: 0 none;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-scrollpanel .p-scrollpanel-bar:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-splitter {
          border: 1px solid #e5e7eb;
          background: #ffffff;
          border-radius: 4px;
          color: var(--text-color);
     }

     .p-splitter .p-splitter-gutter {
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          background: #f9fafb;
     }

     .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
          background: #e5e7eb;
     }

     .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-splitter .p-splitter-gutter-resizing {
          background: #e5e7eb;
     }

     .p-tabview .p-tabview-nav-content {
          scroll-padding-inline: 3rem;
     }

     .p-tabview .p-tabview-nav {
          background: #ffffff;
          border: 1px solid #e5e7eb;
          border-width: 0 0 2px 0;
     }

     .p-tabview .p-tabview-nav li {
          margin-right: 0;
     }

     .p-tabview .p-tabview-nav li .p-tabview-nav-link {
          border: solid #e5e7eb;
          border-width: 0 0 2px 0;
          border-color: transparent transparent #e5e7eb transparent;
          background: #ffffff;
          color: #6b7280;
          padding: 1.25rem;
          font-weight: 700;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          transition: box-shadow 0.2s;
          margin: 0 0 -2px 0;
     }

     .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #BFDBFE;
     }

     .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
          background: #ffffff;
          border-color: #9ca3af;
          color: #6b7280;
     }

     .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
          background: #ffffff;
          border-color: #005693;
          color: #005693;
     }

     .p-tabview .p-tabview-left-icon {
          margin-right: 0.5rem;
     }

     .p-tabview .p-tabview-right-icon {
          margin-left: 0.5rem;
     }

     .p-tabview .p-tabview-close {
          margin-left: 0.5rem;
     }

     .p-tabview .p-tabview-nav-btn.p-link {
          background: #ffffff;
          color: #005693;
          width: 3rem;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          border-radius: 0;
     }

     .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #BFDBFE;
     }

     .p-tabview .p-tabview-panels {
          background: #ffffff;
          padding: 1.25rem;
          border: 0 none;
          color: var(--text-color);
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-toolbar {
          background: #f9fafb;
          border: 1px solid #e5e7eb;
          padding: 1.25rem;
          border-radius: 4px;
          gap: 0.5rem;
     }

     .p-toolbar .p-toolbar-separator {
          margin: 0 0.5rem;
     }

     .p-stepper .p-stepper-nav {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          padding: 0;
          list-style-type: none;
          overflow-x: auto;
     }

     .p-stepper-vertical .p-stepper-nav {
          flex-direction: column;
     }

     .p-stepper-header {
          position: relative;
          display: flex;
          flex: 1 1 auto;
          align-items: center;
     }

     .p-stepper-header:last-of-type {
          flex: initial;
     }

     .p-stepper-header .p-stepper-action {
          border: 0 none;
          display: inline-flex;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
     }

     .p-stepper-header .p-stepper-action:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-stepper.p-stepper-readonly .p-stepper-header {
          cursor: auto;
     }

     .p-stepper-header.p-highlight .p-stepper-action {
          cursor: default;
     }

     .p-stepper-title {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
     }

     .p-stepper-number {
          display: flex;
          align-items: center;
          justify-content: center;
     }

     .p-stepper-separator {
          flex: 1 1 0;
     }

     .p-stepper .p-stepper-nav {
          display: flex;
          justify-content: space-between;
          margin: 0;
          padding: 0;
          list-style-type: none;
     }

     .p-stepper .p-stepper-header {
          padding: 0.5rem;
     }

     .p-stepper .p-stepper-header .p-stepper-action {
          transition: box-shadow 0.2s;
          border-radius: 4px;
          background: #ffffff;
          outline-color: transparent;
     }

     .p-stepper .p-stepper-header .p-stepper-action .p-stepper-number {
          color: var(--text-color);
          border: 1px solid #f3f4f6;
          border-width: 2px;
          background: #ffffff;
          min-width: 2rem;
          height: 2rem;
          line-height: 2rem;
          font-size: 1.143rem;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-stepper .p-stepper-header .p-stepper-action .p-stepper-title {
          margin-left: 0.5rem;
          color: #6b7280;
          font-weight: 700;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-stepper .p-stepper-header .p-stepper-action:not(.p-disabled):focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
          background: #EFF6FF;
          color: #005693;
     }

     .p-stepper .p-stepper-header.p-highlight .p-stepper-title {
          color: var(--text-color);
     }

     .p-stepper .p-stepper-header:not(.p-disabled):focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
          background-color: #005693;
     }

     .p-stepper .p-stepper-panels {
          background: #ffffff;
          padding: 1.25rem;
          color: var(--text-color);
     }

     .p-stepper .p-stepper-separator {
          background-color: #e5e7eb;
          width: 100%;
          height: 2px;
          margin-inline-start: 1rem;
          transition: box-shadow 0.2s;
     }

     .p-stepper.p-stepper-vertical {
          display: flex;
          flex-direction: column;
     }

     .p-stepper.p-stepper-vertical .p-stepper-toggleable-content {
          display: flex;
          flex: 1 1 auto;
          background: #ffffff;
          color: var(--text-color);
     }

     .p-stepper.p-stepper-vertical .p-stepper-panel {
          display: flex;
          flex-direction: column;
          flex: initial;
     }

     .p-stepper.p-stepper-vertical .p-stepper-panel.p-stepper-panel-active {
          flex: 1 1 auto;
     }

     .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-header {
          flex: initial;
     }

     .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-content {
          width: 100%;
          padding-left: 1rem;
     }

     .p-stepper.p-stepper-vertical .p-stepper-panel .p-stepper-separator {
          flex: 0 0 auto;
          width: 2px;
          height: auto;
          margin-inline-start: calc(1.75rem + 2px);
     }

     .p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
          background-color: #005693;
     }

     .p-stepper.p-stepper-vertical .p-stepper-panel:last-of-type .p-stepper-content {
          padding-left: 3rem;
     }

     .p-confirm-popup {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          border-radius: 4px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
     }

     .p-confirm-popup .p-confirm-popup-content {
          padding: 1.25rem;
     }

     .p-confirm-popup .p-confirm-popup-footer {
          text-align: right;
          padding: 0.75rem 1.25rem;
     }

     .p-confirm-popup .p-confirm-popup-footer button {
          margin: 0 0.5rem 0 0;
          width: auto;
     }

     .p-confirm-popup .p-confirm-popup-footer button:last-child {
          margin: 0;
     }

     .p-confirm-popup:after {
          border: solid transparent;
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
     }

     .p-confirm-popup:before {
          border: solid transparent;
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
     }

     .p-confirm-popup.p-confirm-popup-flipped:after {
          border-top-color: #ffffff;
     }

     .p-confirm-popup.p-confirm-popup-flipped:before {
          border-top-color: #ffffff;
     }

     .p-confirm-popup .p-confirm-popup-icon {
          font-size: 1.5rem;
     }

     .p-confirm-popup .p-confirm-popup-icon.p-icon {
          width: 1.5rem;
          height: 1.5rem;
     }

     .p-confirm-popup .p-confirm-popup-message {
          margin-left: 1rem;
     }

     .p-dialog {
          border-radius: 4px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
          border: 0 none;
     }

     .p-dialog .p-dialog-header {
          border-bottom: 1px solid #EF8004;
          background: #E5F1FF;
          color: var(--text-color);
          padding: 7px 1.5rem;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          font-weight: 500;
          font-size: 20px;
     }

     .p-dialog .p-dialog-header .p-dialog-title {
          font-weight: 500;
          font-size: 1.25rem;
     }

     .p-dialog .p-dialog-header .p-dialog-header-icon {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          margin-right: 0.5rem;
     }

     .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #E5F1FF;
     }

     .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
          margin-right: 0;
     }

     .p-dialog .p-dialog-content {
          background: #ffffff;
          color: var(--text-color);
          padding: 0 1.5rem 2rem 1.5rem;
     }

     .p-dialog .p-dialog-content:last-of-type {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-dialog .p-dialog-footer {
          border-top: 0 none;
          background: #ffffff;
          color: var(--text-color);
          padding: 0 1.5rem 1.5rem 1.5rem;
          text-align: right;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-dialog .p-dialog-footer button {
          margin: 0 0.5rem 0 0;
          width: auto;
     }

     .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
          font-size: 2rem;
     }

     .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
          width: 2rem;
          height: 2rem;
     }

     .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
          margin-left: 1rem;
     }

     .p-overlaypanel {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          border-radius: 4px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
     }

     .p-overlaypanel .p-overlaypanel-content {
          padding: 1.25rem;
     }

     .p-overlaypanel .p-overlaypanel-close {
          background: #005693;
          color: #ffffff;
          width: 2rem;
          height: 2rem;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius: 50%;
          position: absolute;
          top: -1rem;
          right: -1rem;
     }

     .p-overlaypanel .p-overlaypanel-close:enabled:hover {
          background: #013357;
          color: #ffffff;
     }

     .p-overlaypanel:after {
          border: solid transparent;
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
     }

     .p-overlaypanel:before {
          border: solid transparent;
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #f2f2f2;
     }

     .p-overlaypanel.p-overlaypanel-flipped:after {
          border-top-color: #ffffff;
     }

     .p-overlaypanel.p-overlaypanel-flipped:before {
          border-top-color: #ffffff;
     }

     .p-sidebar {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
     }

     .p-sidebar .p-sidebar-header {
          padding: 1.25rem;
     }

     .p-sidebar .p-sidebar-header .p-sidebar-close,
     .p-sidebar .p-sidebar-header .p-sidebar-icon {
          width: 2rem;
          height: 2rem;
          color: #6b7280;
          border: 0 none;
          background: transparent;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
     .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
          color: #374151;
          border-color: transparent;
          background: #f3f4f6;
     }

     .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
     .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-sidebar .p-sidebar-header+.p-sidebar-content {
          padding-top: 0;
     }

     .p-sidebar .p-sidebar-content {
          padding: 1.25rem;
     }

     .p-sidebar .p-sidebar-footer {
          padding: 1.25rem;
     }

     .p-tooltip .p-tooltip-text {
          background: var(--text-color);
          color: #ffffff;
          padding: 0.75rem 0.75rem;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
     }

     .p-tooltip.p-tooltip-right .p-tooltip-arrow {
          border-right-color: var(--text-color);
     }

     .p-tooltip.p-tooltip-left .p-tooltip-arrow {
          border-left-color: var(--text-color);
     }

     .p-tooltip.p-tooltip-top .p-tooltip-arrow {
          border-top-color: var(--text-color);
     }

     .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
          border-bottom-color: var(--text-color);
     }

     .p-fileupload .p-fileupload-buttonbar {
          background: #f9fafb;
          padding: 1.25rem;
          border: 1px solid #e5e7eb;
          color: #374151;
          border-bottom: 0 none;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-fileupload .p-fileupload-buttonbar .p-button {
          margin-right: 0.5rem;
     }

     .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-fileupload .p-fileupload-content {
          background: #ffffff;
          padding: 2rem 1rem;
          border: 1px solid #e5e7eb;
          color: var(--text-color);
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-fileupload .p-fileupload-content.p-fileupload-highlight {
          border-color: 1px dashed #005693;
          border-style: dashed;
          background-color: #EFF6FF;
     }

     .p-fileupload .p-progressbar {
          height: 0.25rem;
     }

     .p-fileupload .p-fileupload-row>div {
          padding: 1rem 1rem;
     }

     .p-fileupload.p-fileupload-advanced .p-message {
          margin-top: 0;
     }

     .p-fileupload-choose:not(.p-disabled):hover {
          background: #013357;
          color: #ffffff;
          border-color: #013357;
     }

     .p-fileupload-choose:not(.p-disabled):active {
          background: #005693;
          color: #ffffff;
          border-color: #005693;
     }

     .p-breadcrumb {
          /* background: #ffffff;
          border: 1px solid #e5e7eb; */
          background: none;
          border: none;
          border-radius: 4px;
          padding: 1rem;
     }

     .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
          transition: box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);  
     }

     .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
          color: #33425B !important;
     }

     .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
          margin: 0 0.5rem 0 0.5rem;
          color: var(--text-color);
     }

     .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
          color: var(--text-color);
     }

     .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
          color: #6b7280;
     }

     .p-contextmenu {
          padding: 0.5rem 0;
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          width: 12.5rem;
     }

     .p-contextmenu .p-contextmenu-root-list {
          outline: 0 none;
     }

     .p-contextmenu .p-submenu-list {
          padding: 0.5rem 0;
          background: #ffffff;
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
     }

     .p-contextmenu .p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
          color: var(--text-color);
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: #33425B !important;
          margin-right: 0.5rem;
     }

     .p-contextmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content {
          color: #005693;
          background: #EFF6FF;
     }

     .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: #005693;
     }

     .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-contextmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #005693;
     }

     .p-contextmenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: var(--text-color);
     }

     .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-contextmenu .p-menuitem-separator {
          border-top: 1px solid #e5e7eb;
          margin: 0.25rem 0;
     }

     .p-contextmenu .p-submenu-icon {
          font-size: 0.875rem;
     }

     .p-contextmenu .p-submenu-icon.p-icon {
          width: 0.875rem;
          height: 0.875rem;
     }

     .p-dock .p-dock-list-container {
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.2);
          padding: 0.5rem 0.5rem;
          border-radius: 0.5rem;
     }

     .p-dock .p-dock-list-container .p-dock-list {
          outline: 0 none;
     }

     .p-dock .p-dock-item {
          padding: 0.5rem;
          border-radius: 4px;
     }

     .p-dock .p-dock-item.p-focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.15rem #BFDBFE;
     }

     .p-dock .p-dock-link {
          width: 4rem;
          height: 4rem;
     }

     .p-dock.p-dock-top .p-dock-item-second-prev,
     .p-dock.p-dock-top .p-dock-item-second-next,
     .p-dock.p-dock-bottom .p-dock-item-second-prev,
     .p-dock.p-dock-bottom .p-dock-item-second-next {
          margin: 0 0.9rem;
     }

     .p-dock.p-dock-top .p-dock-item-prev,
     .p-dock.p-dock-top .p-dock-item-next,
     .p-dock.p-dock-bottom .p-dock-item-prev,
     .p-dock.p-dock-bottom .p-dock-item-next {
          margin: 0 1.3rem;
     }

     .p-dock.p-dock-top .p-dock-item-current,
     .p-dock.p-dock-bottom .p-dock-item-current {
          margin: 0 1.5rem;
     }

     .p-dock.p-dock-left .p-dock-item-second-prev,
     .p-dock.p-dock-left .p-dock-item-second-next,
     .p-dock.p-dock-right .p-dock-item-second-prev,
     .p-dock.p-dock-right .p-dock-item-second-next {
          margin: 0.9rem 0;
     }

     .p-dock.p-dock-left .p-dock-item-prev,
     .p-dock.p-dock-left .p-dock-item-next,
     .p-dock.p-dock-right .p-dock-item-prev,
     .p-dock.p-dock-right .p-dock-item-next {
          margin: 1.3rem 0;
     }

     .p-dock.p-dock-left .p-dock-item-current,
     .p-dock.p-dock-right .p-dock-item-current {
          margin: 1.5rem 0;
     }

     @media screen and (max-width: 960px) {

          .p-dock.p-dock-top .p-dock-list-container,
          .p-dock.p-dock-bottom .p-dock-list-container {
               overflow-x: auto;
               width: 100%;
          }

          .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
          .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
               margin: 0 auto;
          }

          .p-dock.p-dock-left .p-dock-list-container,
          .p-dock.p-dock-right .p-dock-list-container {
               overflow-y: auto;
               height: 100%;
          }

          .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
          .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
               margin: auto 0;
          }

          .p-dock .p-dock-list .p-dock-item {
               transform: none;
               margin: 0;
          }
     }

     .p-megamenu {
          padding: 0.5rem;
          background: #f9fafb;
          color: var(--text-color);
          border: 1px solid #e5e7eb;
          border-radius: 4px;
     }

     .p-megamenu .p-megamenu-root-list {
          outline: 0 none;
     }

     .p-megamenu .p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
          color: var(--text-color);
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: var(--text-color);
          margin-right: 0.5rem;
     }

     .p-megamenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content {
          color: #005693;
          background: #EFF6FF;
     }

     .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: #005693;
     }

     .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-megamenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #005693;
     }

     .p-megamenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: var(--text-color);
     }

     .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-megamenu .p-megamenu-panel {
          background: #ffffff;
          color: var(--text-color);
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
     }

     .p-megamenu .p-submenu-header {
          margin: 0;
          padding: 0.75rem 1.25rem;
          color: #374151;
          background: #ffffff;
          font-weight: 700;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
     }

     .p-megamenu .p-submenu-list {
          padding: 0.5rem 0;
          width: 12.5rem;
     }

     .p-megamenu .p-submenu-list .p-menuitem-separator {
          border-top: 1px solid #e5e7eb;
          margin: 0.25rem 0;
     }

     .p-megamenu.p-megamenu-vertical {
          width: 12.5rem;
          padding: 0.5rem 0;
     }

     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: var(--text-color);
          margin-right: 0.5rem;
     }

     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
          margin-left: 0.5rem;
     }

     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-menu {
          padding: 0.5rem 0;
          background: #ffffff;
          color: var(--text-color);
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          width: 12.5rem;
     }

     .p-menu .p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link {
          color: var(--text-color);
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: var(--text-color);
          margin-right: 0.5rem;
     }

     .p-menu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-menu .p-menuitem.p-highlight>.p-menuitem-content {
          color: #005693;
          background: #EFF6FF;
     }

     .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: #005693;
     }

     .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-menu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #005693;
     }

     .p-menu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: var(--text-color);
     }

     .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-menu.p-menu-overlay {
          background: #ffffff;
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-menu .p-submenu-header {
          margin: 0;
          padding: 0.75rem 1.25rem;
          color: #374151;
          background: #ffffff;
          font-weight: 700;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
     }

     .p-menu .p-menuitem-separator {
          border-top: 1px solid #e5e7eb;
          margin: 0.25rem 0;
     }

     .p-menu .p-menuitem-badge {
          background: #005693;
          color: #ffffff;
          font-size: 0.75rem;
          font-weight: 700;
          min-width: 1.5rem;
          height: 1.5rem;
          line-height: 1.5rem;
          border-radius: 4px;
          margin-left: 0.5rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
     }

     .p-menubar {
          padding: 0.5rem;
          background: #f9fafb;
          color: var(--text-color);
          border: 1px solid #e5e7eb;
          border-radius: 4px;
     }

     .p-menubar .p-menubar-root-list {
          outline: 0 none;
     }

     .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 4px;
     }

     .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: var(--text-color);
          margin-right: 0.5rem;
     }

     .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
          margin-left: 0.5rem;
     }

     .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-menubar .p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link {
          color: var(--text-color);
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: var(--text-color);
          margin-right: 0.5rem;
     }

     .p-menubar .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-menubar .p-menuitem.p-highlight>.p-menuitem-content {
          color: #005693;
          background: #EFF6FF;
     }

     .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: #005693;
     }

     .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-menubar .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #005693;
     }

     .p-menubar .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: var(--text-color);
     }

     .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-menubar .p-submenu-list {
          padding: 0.5rem 0;
          background: #ffffff;
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          width: 12.5rem;
          border-radius: 4px;
     }

     .p-menubar .p-submenu-list .p-menuitem-separator {
          border-top: 1px solid #e5e7eb;
          margin: 0.25rem 0;
     }

     .p-menubar .p-submenu-list .p-submenu-icon {
          font-size: 0.875rem;
     }

     @media screen and (max-width: 960px) {
          .p-menubar {
               position: relative;
          }

          .p-menubar .p-menubar-button {
               display: flex;
               width: 2rem;
               height: 2rem;
               color: #6b7280;
               border-radius: 50%;
               transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          }

          .p-menubar .p-menubar-button:hover {
               color: #6b7280;
               background: #f3f4f6;
          }

          .p-menubar .p-menubar-button:focus {
               outline: 0 none;
               outline-offset: 0;
               box-shadow: 0 0 0 0.2rem #BFDBFE;
          }

          .p-menubar .p-menubar-root-list {
               position: absolute;
               display: none;
               padding: 0.5rem 0;
               background: #ffffff;
               border: 0 none;
               box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
               width: 100%;
          }

          .p-menubar .p-menubar-root-list .p-menuitem-separator {
               border-top: 1px solid #e5e7eb;
               margin: 0.25rem 0;
          }

          .p-menubar .p-menubar-root-list .p-submenu-icon {
               font-size: 0.875rem;
          }

          .p-menubar .p-menubar-root-list .p-menuitem {
               width: 100%;
               position: static;
          }

          .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
               margin-left: auto;
               transition: transform 0.2s;
          }

          .p-menubar .p-menubar-root-list .p-menuitem.p-menuitem-active>.p-menuitem-content>.p-menuitem-link>.p-submenu-icon {
               transform: rotate(-180deg);
          }

          .p-menubar .p-menubar-root-list .p-submenu-list {
               width: 100%;
               position: static;
               box-shadow: none;
               border: 0 none;
          }

          .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
               transition: transform 0.2s;
               transform: rotate(90deg);
          }

          .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-content>.p-menuitem-link>.p-submenu-icon {
               transform: rotate(-90deg);
          }

          .p-menubar .p-menubar-root-list .p-menuitem {
               width: 100%;
               position: static;
          }

          .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
               padding-left: 2.25rem;
          }

          .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
               padding-left: 3.75rem;
          }

          .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
               padding-left: 5.25rem;
          }

          .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
               padding-left: 6.75rem;
          }

          .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
               padding-left: 8.25rem;
          }

          .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
               display: flex;
               flex-direction: column;
               top: 100%;
               left: 0;
               z-index: 1;
          }
     }

     .p-panelmenu .p-panelmenu-header {
          outline: 0 none;
     }

     .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
          border: 1px solid #e5e7eb;
          color: #6b7280;
          background: #f9fafb;
          border-radius: 4px;
          transition: box-shadow 0.2s;
     }

     .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
          color: #6b7280;
          padding: 1.25rem;
          font-weight: 700;
     }

     .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon {
          margin-right: 0.5rem;
     }

     .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
          margin-right: 0.5rem;
     }

     .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #BFDBFE;
     }

     .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
          background: #f3f4f6;
          border-color: #e5e7eb;
          color: #374151;
     }

     .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
          background: #f9fafb;
          border-color: #e5e7eb;
          color: #374151;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          margin-bottom: 0;
     }

     .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
          border-color: #e5e7eb;
          background: #f3f4f6;
          color: #374151;
     }

     .p-panelmenu .p-panelmenu-content {
          padding: 0.5rem 0;
          border: 1px solid #e5e7eb;
          background: #ffffff;
          color: var(--text-color);
          border-top: 0;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
          outline: 0 none;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link {
          color: var(--text-color);
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: var(--text-color);
          margin-right: 0.5rem;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content {
          color: #005693;
          background: #EFF6FF;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: #005693;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #005693;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: var(--text-color);
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
          margin-right: 0.5rem;
     }

     .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
          border-top: 1px solid #e5e7eb;
          margin: 0.25rem 0;
     }

     .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
          padding: 0 0 0 1rem;
     }

     .p-panelmenu .p-panelmenu-panel {
          margin-bottom: 4px;
     }

     .p-slidemenu {
          padding: 0.5rem 0;
          background: #ffffff;
          color: var(--text-color);
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          width: 12.5rem;
     }

     .p-slidemenu .p-slidemenu-root-list {
          outline: 0 none;
     }

     .p-slidemenu .p-submenu-list {
          outline: 0 none;
     }

     .p-slidemenu .p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
          color: var(--text-color);
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: var(--text-color);
          margin-right: 0.5rem;
     }

     .p-slidemenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content {
          color: #005693;
          background: #EFF6FF;
     }

     .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: #005693;
     }

     .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-slidemenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #005693;
     }

     .p-slidemenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: var(--text-color);
     }

     .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-slidemenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-slidemenu.p-slidemenu-overlay {
          background: #ffffff;
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-slidemenu .p-slidemenu-list {
          padding: 0.5rem 0;
          background: #ffffff;
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-slidemenu .p-menuitem-separator {
          border-top: 1px solid #e5e7eb;
          margin: 0.25rem 0;
     }

     .p-slidemenu .p-slidemenu-icon {
          font-size: 0.875rem;
     }

     .p-slidemenu .p-icon {
          width: 0.875rem;
          height: 0.875rem;
     }

     .p-slidemenu .p-slidemenu-backward {
          padding: 0.75rem 1.25rem;
          color: var(--text-color);
     }

     .p-slidemenu .p-slidemenu-backward:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #BFDBFE;
     }

     .p-slidemenu .p-menuitem-badge {
          background: #005693;
          color: #ffffff;
          font-size: 0.75rem;
          font-weight: 700;
          min-width: 1.5rem;
          height: 1.5rem;
          line-height: 1.5rem;
          border-radius: 4px;
          margin-left: 0.5rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
     }

     .p-steps .p-steps-item .p-menuitem-link {
          background: transparent;
          transition: box-shadow 0.2s;
          border-radius: 4px;
          background: #ffffff;
     }

     .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
          color: var(--text-color);
          border: 1px solid #f3f4f6;
          background: #ffffff;
          min-width: 2rem;
          height: 2rem;
          line-height: 2rem;
          font-size: 1.143rem;
          z-index: 1;
          border-radius: 50%;
     }

     .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
          margin-top: 0.5rem;
          color: #6b7280;
     }

     .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-steps .p-steps-item.p-highlight .p-steps-number {
          background: #EFF6FF;
          color: #005693;
     }

     .p-steps .p-steps-item.p-highlight .p-steps-title {
          font-weight: 700;
          color: var(--text-color);
     }

     .p-steps .p-steps-item:before {
          content: " ";
          border-top: 1px solid #e5e7eb;
          width: 100%;
          top: 50%;
          left: 0;
          display: block;
          position: absolute;
          margin-top: -1rem;
     }

     .p-tabmenu .p-tabmenu-nav {
          background: #ffffff;
          border: 1px solid #e5e7eb;
          border-width: 0 0 2px 0;
     }

     .p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
          background: #005693;
          color: #ffffff;
          font-size: 0.75rem;
          font-weight: 700;
          min-width: 1.5rem;
          height: 1.5rem;
          line-height: 1.5rem;
          border-radius: 4px;
          margin-left: 0.5rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
     }

     .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
          margin-right: 0;
     }

     .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
          border: solid #e5e7eb;
          border-width: 0 0 2px 0;
          border-color: transparent transparent #e5e7eb transparent;
          background: #ffffff;
          color: #6b7280;
          padding: 1.25rem;
          font-weight: 700;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          transition: box-shadow 0.2s;
          margin: 0 0 -2px 0;
     }

     .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
          margin-right: 0.5rem;
     }

     .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #BFDBFE;
     }

     .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
          background: #ffffff;
          border-color: #9ca3af;
          color: #6b7280;
     }

     .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
          background: #ffffff;
          border-color: #005693;
          color: #005693;
     }

     .p-tabmenu .p-tabmenu-left-icon {
          margin-right: 0.5rem;
     }

     .p-tabmenu .p-tabmenu-right-icon {
          margin-left: 0.5rem;
     }

     .p-tabmenu .p-tabmenu-nav-btn.p-link {
          background: #ffffff;
          color: #005693;
          width: 3rem;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          border-radius: 0;
     }

     .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: inset 0 0 0 0.2rem #BFDBFE;
     }

     .p-tieredmenu {
          padding: 0.5rem 0;
          background: #ffffff;
          color: var(--text-color);
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          width: 12.5rem;
     }

     .p-tieredmenu.p-tieredmenu-overlay {
          background: #ffffff;
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
     }

     .p-tieredmenu .p-tieredmenu-root-list {
          outline: 0 none;
     }

     .p-tieredmenu .p-submenu-list {
          padding: 0.5rem 0;
          background: #ffffff;
          border: 0 none;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
     }

     .p-tieredmenu .p-menuitem>.p-menuitem-content {
          color: var(--text-color);
          transition: box-shadow 0.2s;
          border-radius: 0;
     }

     .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link {
          color: var(--text-color);
          padding: 0.75rem 1.25rem;
          user-select: none;
     }

     .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-icon {
          color: #6b7280;
          margin-right: 0.5rem;
     }

     .p-tieredmenu .p-menuitem>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content {
          color: #005693;
          background: #EFF6FF;
     }

     .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: #005693;
     }

     .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-tieredmenu .p-menuitem.p-highlight>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: #005693;
     }

     .p-tieredmenu .p-menuitem.p-highlight.p-focus>.p-menuitem-content {
          background: rgba(59, 130, 246, 0.24);
     }

     .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content {
          color: var(--text-color);
          background: #e5e7eb;
     }

     .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-menuitem-icon,
     .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus>.p-menuitem-content .p-menuitem-link .p-submenu-icon {
          color: var(--text-color);
     }

     .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
          color: var(--text-color);
          background: #f3f4f6;
     }

     .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
          color: var(--text-color);
     }

     .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon,
     .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
          color: #6b7280;
     }

     .p-tieredmenu .p-menuitem-separator {
          border-top: 1px solid #e5e7eb;
          margin: 0.25rem 0;
     }

     .p-tieredmenu .p-submenu-icon {
          font-size: 0.875rem;
     }

     .p-tieredmenu .p-submenu-icon.p-icon {
          width: 0.875rem;
          height: 0.875rem;
     }

     .p-inline-message {
          padding: 0.75rem 0.75rem;
          margin: 0;
          border-radius: 4px;
     }

     .p-inline-message.p-inline-message-info {
          background: rgba(219, 234, 254, 0.7);
          border: solid #005693;
          border-width: 0px;
          color: #005693;
     }

     .p-inline-message.p-inline-message-info .p-inline-message-icon {
          color: #005693;
     }

     .p-inline-message.p-inline-message-success {
          background: rgba(228, 248, 240, 0.7);
          border: solid #1ea97c;
          border-width: 0px;
          color: #1ea97c;
     }

     .p-inline-message.p-inline-message-success .p-inline-message-icon {
          color: #1ea97c;
     }

     .p-inline-message.p-inline-message-warn {
          background: rgba(255, 242, 226, 0.7);
          border: solid #cc8925;
          border-width: 0px;
          color: #cc8925;
     }

     .p-inline-message.p-inline-message-warn .p-inline-message-icon {
          color: #cc8925;
     }

     .p-inline-message.p-inline-message-error {
          background: rgba(255, 231, 230, 0.7);
          border: solid #ff5757;
          border-width: 0px;
          color: #ff5757;
     }

     .p-inline-message.p-inline-message-error .p-inline-message-icon {
          color: #ff5757;
     }

     .p-inline-message .p-inline-message-icon {
          font-size: 1rem;
          margin-right: 0.5rem;
     }

     .p-inline-message .p-icon {
          width: 1rem;
          height: 1rem;
     }

     .p-inline-message .p-inline-message-text {
          font-size: 1rem;
     }

     .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
          margin-right: 0;
     }

     .p-message {
          margin: 1rem 0;
          border-radius: 4px;
     }

     .p-message .p-message-wrapper {
          padding: 1.25rem 1.75rem;
     }

     .p-message .p-message-close {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background: transparent;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-message .p-message-close:hover {
          background: rgba(255, 255, 255, 0.5);
     }

     .p-message .p-message-close:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-message.p-message-info {
          background: rgba(219, 234, 254, 0.7);
          border: solid #005693;
          border-width: 0 0 0 6px;
          color: #005693;
     }

     .p-message.p-message-info .p-message-icon {
          color: #005693;
     }

     .p-message.p-message-info .p-message-close {
          color: #005693;
     }

     .p-message.p-message-success {
          background: rgba(228, 248, 240, 0.7);
          border: solid #1ea97c;
          border-width: 0 0 0 6px;
          color: #1ea97c;
     }

     .p-message.p-message-success .p-message-icon {
          color: #1ea97c;
     }

     .p-message.p-message-success .p-message-close {
          color: #1ea97c;
     }

     .p-message.p-message-warn {
          background: rgba(255, 242, 226, 0.7);
          border: solid #cc8925;
          border-width: 0 0 0 6px;
          color: #cc8925;
     }

     .p-message.p-message-warn .p-message-icon {
          color: #cc8925;
     }

     .p-message.p-message-warn .p-message-close {
          color: #cc8925;
     }

     .p-message.p-message-error {
          background: rgba(255, 231, 230, 0.7);
          border: solid #ff5757;
          border-width: 0 0 0 6px;
          color: #ff5757;
     }

     .p-message.p-message-error .p-message-icon {
          color: #ff5757;
     }

     .p-message.p-message-error .p-message-close {
          color: #ff5757;
     }

     .p-message .p-message-text {
          font-size: 1rem;
          font-weight: 500;
     }

     .p-message .p-message-icon {
          font-size: 1.5rem;
          margin-right: 0.5rem;
     }

     .p-message .p-icon {
          width: 1.5rem;
          height: 1.5rem;
     }

     .p-message .p-message-summary {
          font-weight: 700;
     }

     .p-message .p-message-detail {
          margin-left: 0.5rem;
     }

     .p-toast {
          opacity: 1;
     }

     .p-toast .p-toast-message {
          margin: 0 0 1rem 0;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px;
     }

     .p-toast .p-toast-message .p-toast-message-content {
          padding: 1rem;
          border-width: 0 0 0 6px;
     }

     .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
          margin: 0 0 0 1rem;
     }

     .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
          font-size: 2rem;
     }

     .p-toast .p-toast-message .p-toast-message-content .p-icon:not(.p-toast-icon-close-icon) {
          width: 2rem;
          height: 2rem;
     }

     .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
          font-weight: 700;
     }

     .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
          margin: 0.5rem 0 0 0;
     }

     .p-toast .p-toast-message .p-toast-icon-close {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background: transparent;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-toast .p-toast-message .p-toast-icon-close:hover {
          background: rgba(255, 255, 255, 0.5);
     }

     .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-toast .p-toast-message.p-toast-message-info {
          background: rgba(219, 234, 254, 0.7);
          border: solid #005693;
          border-width: 0 0 0 6px;
          color: #005693;
     }

     .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
     .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
          color: #005693;
     }

     .p-toast .p-toast-message.p-toast-message-success {
          background: rgba(228, 248, 240, 0.7);
          border: solid #1ea97c;
          border-width: 0 0 0 6px;
          color: #1ea97c;
     }

     .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
     .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
          color: #1ea97c;
     }

     .p-toast .p-toast-message.p-toast-message-warn {
          background: rgba(255, 242, 226, 0.7);
          border: solid #cc8925;
          border-width: 0 0 0 6px;
          color: #cc8925;
     }

     .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
     .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
          color: #cc8925;
     }

     .p-toast .p-toast-message.p-toast-message-error {
          background: rgba(255, 231, 230, 0.7);
          border: solid #ff5757;
          border-width: 0 0 0 6px;
          color: #ff5757;
     }

     .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
     .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
          color: #ff5757;
     }

     .p-galleria .p-galleria-close {
          margin: 0.5rem;
          background: transparent;
          color: #f9fafb;
          width: 4rem;
          height: 4rem;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius: 50%;
     }

     .p-galleria .p-galleria-close .p-galleria-close-icon {
          font-size: 2rem;
     }

     .p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
          width: 2rem;
          height: 2rem;
     }

     .p-galleria .p-galleria-close:hover {
          background: rgba(255, 255, 255, 0.1);
          color: #f9fafb;
     }

     .p-galleria .p-galleria-item-nav {
          background: transparent;
          color: #f9fafb;
          width: 4rem;
          height: 4rem;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius: 4px;
          margin: 0 0.5rem;
     }

     .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
     .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
          font-size: 2rem;
     }

     .p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
          width: 2rem;
          height: 2rem;
     }

     .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
          background: rgba(255, 255, 255, 0.1);
          color: #f9fafb;
     }

     .p-galleria .p-galleria-caption {
          background: rgba(0, 0, 0, 0.5);
          color: #f9fafb;
          padding: 1rem;
     }

     .p-galleria .p-galleria-indicators {
          padding: 1rem;
     }

     .p-galleria .p-galleria-indicators .p-galleria-indicator button {
          background-color: #d1d5db;
          width: 1rem;
          height: 1rem;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius: 50%;
     }

     .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
          background: #9ca3af;
     }

     .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
          background: #EFF6FF;
          color: #005693;
     }

     .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
     .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
          margin-right: 0.5rem;
     }

     .p-galleria.p-galleria-indicators-left .p-galleria-indicator,
     .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
          margin-bottom: 0.5rem;
     }

     .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
          background: rgba(0, 0, 0, 0.5);
     }

     .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
          background: rgba(255, 255, 255, 0.4);
     }

     .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
          background: rgba(255, 255, 255, 0.6);
     }

     .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
          background: #EFF6FF;
          color: #005693;
     }

     .p-galleria .p-galleria-thumbnail-container {
          background: rgba(0, 0, 0, 0.9);
          padding: 1rem 0.25rem;
     }

     .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
     .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
          margin: 0.5rem;
          background-color: transparent;
          color: #f9fafb;
          width: 2rem;
          height: 2rem;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius: 50%;
     }

     .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
     .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
          background: rgba(255, 255, 255, 0.1);
          color: #f9fafb;
     }

     .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-galleria-mask {
          --maskbg: rgba(0, 0, 0, 0.9);
     }

     .p-image-mask {
          --maskbg: rgba(0, 0, 0, 0.9);
     }

     .p-image-preview-indicator {
          background-color: transparent;
          color: #f8f9fa;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-image-preview-container:hover>.p-image-preview-indicator {
          background-color: rgba(0, 0, 0, 0.5);
     }

     .p-image-toolbar {
          padding: 1rem;
     }

     .p-image-action.p-link {
          color: #f8f9fa;
          background-color: transparent;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
          margin-right: 0.5rem;
     }

     .p-image-action.p-link:last-child {
          margin-right: 0;
     }

     .p-image-action.p-link:hover {
          color: #f8f9fa;
          background-color: rgba(255, 255, 255, 0.1);
     }

     .p-image-action.p-link i {
          font-size: 1.5rem;
     }

     .p-image-action.p-link .p-icon {
          width: 1.5rem;
          height: 1.5rem;
     }

     .p-avatar {
          background-color: #e5e7eb;
          border-radius: 4px;
     }

     .p-avatar.p-avatar-lg {
          width: 3rem;
          height: 3rem;
          font-size: 1.5rem;
     }

     .p-avatar.p-avatar-lg .p-avatar-icon {
          font-size: 1.5rem;
     }

     .p-avatar.p-avatar-xl {
          width: 4rem;
          height: 4rem;
          font-size: 2rem;
     }

     .p-avatar.p-avatar-xl .p-avatar-icon {
          font-size: 2rem;
     }

     .p-avatar-group .p-avatar {
          border: 2px solid #ffffff;
     }

     .p-badge {
          background: #005693;
          color: #ffffff;
          font-size: 0.75rem;
          font-weight: 700;
          min-width: 1.5rem;
          height: 1.5rem;
          line-height: 1.5rem;
     }

     .p-badge.p-badge-secondary {
          background-color: #64748b;
          color: #ffffff;
     }

     .p-badge.p-badge-success {
          background-color: #22c55e;
          color: #ffffff;
     }

     .p-badge.p-badge-info {
          background-color: #0ea5e9;
          color: #ffffff;
     }

     .p-badge.p-badge-warning {
          background-color: #f97316;
          color: #ffffff;
     }

     .p-badge.p-badge-danger {
          background-color: #ef4444;
          color: #ffffff;
     }

     .p-badge.p-badge-lg {
          font-size: 1.125rem;
          min-width: 2.25rem;
          height: 2.25rem;
          line-height: 2.25rem;
     }

     .p-badge.p-badge-xl {
          font-size: 1.5rem;
          min-width: 3rem;
          height: 3rem;
          line-height: 3rem;
     }

     .p-chip {
          background-color: #e5e7eb;
          color: var(--text-color);
          border-radius: 16px;
          padding: 0 0.75rem;
     }

     .p-chip .p-chip-text {
          line-height: 1.5;
          margin-top: 0.375rem;
          margin-bottom: 0.375rem;
     }

     .p-chip .p-chip-icon {
          margin-right: 0.5rem;
     }

     .p-chip .pi-chip-remove-icon {
          margin-left: 0.5rem;
     }

     .p-chip img {
          width: 2.25rem;
          height: 2.25rem;
          margin-left: -0.75rem;
          margin-right: 0.5rem;
     }

     .p-chip .pi-chip-remove-icon {
          border-radius: 4px;
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-chip .pi-chip-remove-icon:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-chip .pi-chip-remove-icon:focus {
          outline: 0 none;
     }

     .p-inplace .p-inplace-display {
          padding: 0.75rem 0.75rem;
          border-radius: 4px;
          transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-inplace .p-inplace-display:not(.p-disabled):hover {
          background: #f3f4f6;
          color: var(--text-color);
     }

     .p-inplace .p-inplace-display:focus {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.2rem #BFDBFE;
     }

     .p-metergroup {
          display: flex;
     }

     .p-metergroup-meters {
          display: flex;
     }

     .p-metergroup-vertical .p-metergroup-meters {
          flex-direction: column;
     }

     .p-metergroup-labels {
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          padding: 0;
          list-style-type: none;
     }

     .p-metergroup-vertical .p-metergroup-labels {
          align-items: start;
     }

     .p-metergroup-labels-vertical {
          flex-direction: column;
     }

     .p-metergroup-label {
          display: inline-flex;
          align-items: center;
     }

     .p-metergroup-label-marker {
          display: inline-flex;
     }

     .p-metergroup {
          gap: 1rem;
     }

     .p-metergroup .p-metergroup-meters {
          background: #e5e7eb;
          border-radius: 4px;
     }

     .p-metergroup .p-metergroup-meter {
          border: 0 none;
          background: #005693;
     }

     .p-metergroup .p-metergroup-labels .p-metergroup-label {
          gap: 0.5rem;
     }

     .p-metergroup .p-metergroup-labels .p-metergroup-label-marker {
          background: #005693;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 100%;
     }

     .p-metergroup .p-metergroup-labels .p-metergroup-label-icon {
          width: 1rem;
          height: 1rem;
     }

     .p-metergroup .p-metergroup-labels.p-metergroup-labels-vertical {
          gap: 0.5rem;
     }

     .p-metergroup .p-metergroup-labels.p-metergroup-labels-horizontal {
          gap: 1rem;
     }

     .p-metergroup.p-metergroup-horizontal {
          flex-direction: column;
     }

     .p-metergroup.p-metergroup-horizontal .p-metergroup-meters {
          height: 0.5rem;
     }

     .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
     }

     .p-metergroup.p-metergroup-horizontal .p-metergroup-meter:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-metergroup.p-metergroup-vertical {
          flex-direction: row;
     }

     .p-metergroup.p-metergroup-vertical .p-metergroup-meters {
          width: 0.5rem;
          height: 100%;
     }

     .p-metergroup.p-metergroup-vertical .p-metergroup-meter:first-of-type {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
     }

     .p-metergroup.p-metergroup-vertical .p-metergroup-meter:last-of-type {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
     }

     .p-progressbar {
          border: 0 none;
          height: 1.5rem;
          background: #e5e7eb;
          border-radius: 4px;
     }

     .p-progressbar .p-progressbar-value {
          border: 0 none;
          margin: 0;
          background: #005693;
     }

     .p-progressbar .p-progressbar-label {
          color: #ffffff;
          line-height: 1.5rem;
     }

     .p-scrolltop {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
     }

     .p-scrolltop.p-link {
          background: rgba(0, 0, 0, 0.7);
     }

     .p-scrolltop.p-link:hover {
          background: rgba(0, 0, 0, 0.8);
     }

     .p-scrolltop .p-scrolltop-icon {
          font-size: 1.5rem;
          color: #f9fafb;
     }

     .p-scrolltop .p-icon {
          width: 1.5rem;
          height: 1.5rem;
     }

     .p-skeleton {
          background-color: #e5e7eb;
          border-radius: 4px;
     }

     .p-skeleton:after {
          background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
     }

     .p-tag {
          background: #005693;
          color: #ffffff;
          font-size: 0.75rem;
          font-weight: 700;
          padding: 0.25rem 0.4rem;
          border-radius: 4px;
     }

     .p-tag.p-tag-success {
          background-color: #22c55e;
          color: #ffffff;
     }

     .p-tag.p-tag-info {
          background-color: #0ea5e9;
          color: #ffffff;
     }

     .p-tag.p-tag-warning {
          background-color: #f97316;
          color: #ffffff;
     }

     .p-tag.p-tag-danger {
          background-color: #ef4444;
          color: #ffffff;
     }

     .p-tag .p-tag-icon {
          margin-right: 0.25rem;
          font-size: 0.75rem;
     }

     .p-tag .p-icon {
          width: 0.75rem;
          height: 0.75rem;
     }

     .p-terminal {
          background: #ffffff;
          color: var(--text-color);
          border: 1px solid #e5e7eb;
          padding: 1.25rem;
     }

     .p-terminal .p-terminal-input {
          font-family: var(--font-family);
          font-feature-settings: var(--font-feature-settings, normal);
          font-size: 1rem;
     }
}

@layer primeng {
     .p-button-label {
          font-weight: 500;
     }

     .p-selectbutton>.p-button,
     .p-togglebutton.p-button {
          transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-accordion .p-accordion-header .p-accordion-header-link {
          transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-tabview .p-tabview-nav li .p-tabview-nav-link {
          transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
          transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
          background-color: #005693;
     }

     .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
          background-color: #005693;
     }

     .p-button:focus {
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 black;
     }

     .p-button.p-button-secondary:enabled:focus {
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 black;
     }

     .p-button.p-button-success:enabled:focus {
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #88eaac, 0 1px 2px 0 black;
     }

     .p-button.p-button-info:enabled:focus {
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #83d3f8, 0 1px 2px 0 black;
     }

     .p-button.p-button-warning:enabled:focus {
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #fcb98b, 0 1px 2px 0 black;
     }

     .p-button.p-button-help:enabled:focus {
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #d4aafb, 0 1px 2px 0 black;
     }

     .p-button.p-button-danger:enabled:focus {
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 black;
     }

     .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
          box-shadow: inset 0 2px 0 0 #005693;
     }

     .p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
          box-shadow: inset 0 -2px 0 0 #005693;
     }

     .p-speeddial-item.p-focus>.p-speeddial-action {
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 black;
     }

     .p-toast-message {
          backdrop-filter: blur(10px);
     }

     .p-inline-message-text {
          font-weight: 500;
     }

     .p-picklist-buttons .p-button,
     .p-orderlist-controls .p-button {
          transition: opacity 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
     }

     .p-steps .p-steps-item.p-highlight .p-steps-number {
          background: #005693;
          color: #ffffff;
     }
}
.closeIcon {
     position: absolute;
     top: 12px;
     right: 12px;
     font-size: 24px;
     color: var(--surface-700);
     cursor: pointer;
}
/* Common styles for Data view starts */
.dataView .title {
     color: var(--primary-color);
     font-weight: 500;
     font-size: 1.1rem;
}
.dataView .clr-secondary {
     color: var(--secondary-color);
}
.dataView .clr-primary {
     color: var(--primary-color);
}
/* Common styles for Data view ends */
.trDisabled {
     color: rgba(51, 66, 91, 0.5)
}
.trEnabled {
     color: rgba(51, 66, 91, 1)
}
.breadCrumbHome {
     color: var(--text-color);
}
.p-fieldset-border-none .p-fieldset {
     border: none !important;
     padding:0px;
     font-family: var(--font-family);
     font-feature-settings: var(--font-feature-settings, normal);
     font-size: 1rem;
     font-weight: normal;
}
.p-fieldset-border .p-fieldset {
     border: 1px solid #e5e7eb !important;
     padding: 0 1.5rem 2rem 1.5rem;
     font-family: var(--font-family);
     font-feature-settings: var(--font-feature-settings, normal);
     font-size: 1rem;
     font-weight: normal;
}
.p-fieldset-border-none .p-fieldset-legend a {
     font-family: var(--font-family) !important;
     font-feature-settings: var(--font-feature-settings, normal) !important;
     font-size: 1rem !important;
     font-weight: normal !important;
}
.trans-history .p-tabview .p-tabview-panels {
     padding-left: 0px !important;
     padding-right:0px !important;
}
.clr-primary {
     color: var(--primary-color) !important;
}
.clr-secondary {
     color: var(--secondary-color) !important;
}
.table-row-non-editable {
     background: #EBCDD3 !important;
}
.table-row-editable {
     background: #eff6ff !important;
}
.trans-credit-correction .p-card .p-card-body {
     padding-left: 0px !important;
     padding-right: 0px !important;
}
.trans-credit-correction .p-tabview .p-tabview-panels {
     padding-left: 0px !important;
     padding-right: 0px !important;
}
.trans-history .p-card .p-card-title {
     padding-left: 1.25rem !important;
     padding-right: 1.25rem !important;
}
.trans-history .p-card .p-card-content {
     padding-top: 0px !important;
}
.formArrayRemove {
     display: flex;
     align-items: end;
     justify-content: center;
     margin-bottom: 26px;
     cursor: pointer;
}
.transactionTypeActionBtn {
     position: fixed;
     bottom: 0px;
     background: var(--surface-a);;
     width: 100%;
     left: 0px;
     padding: 24px;
}
.edit-trans-type-credit .p-card .p-card-content {
     padding-top: 0px !important;
}
.edit-trans-type-credit .p-card .p-card-body {
     padding: 0.7rem 1.25rem 0rem;
}